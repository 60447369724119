import { useEffect, useState } from 'react';
import { db, fb } from '../common/firebase/Firestore';
import * as COLLECTIONS from '../common/firebase/collections';
import * as firebase from "firebase/app";
import { getDate, sameDay } from '../common/helpers/Date';
import * as NAME from '../common/constant/name';
import * as Price from '../common/helpers/Price'

const startOrder = (item, _onAddSuccess) => {

}

export const getMaxSlot = (level) => {
    var number = 3;
    switch (level) {
        case "vip1": number = 30; break;
        case "vip2": number = 100; break;
        case "vip3": number = 300; break;
    }
    return number;
}

export const getRemainCartSlot = (user) => {
    if (!user)
        return 0;

    let lastTime = user.last_time_added_to_cart;
    let level = user.level;
    let added = user.addedIntoCart;
    console.log("added ===========: ", added);
    if (lastTime && added) {
        var remainSlot = 0;

        //Get current day
        const lastDay = lastTime.toDate();
        const currentDay = firebase.firestore.Timestamp.now().toDate();

        //Get Maximum slot
        const maxSlot = getMaxSlot(level);
        console.log("Last day: ", lastDay);
        console.log("Current day: ", currentDay);

        //Count remain
        if (sameDay(lastDay, currentDay)) {
            remainSlot = maxSlot - added;
        } else {
            remainSlot = maxSlot;
            //Update DB
            console.log("RESET CART NUMBER ===========: ", currentDay);
            resetAddedCartNumber(user);
        }

        if (remainSlot < 0)
            remainSlot = 0;

        return remainSlot;
    } else return getMaxSlot(level);
}

const resetAddedCartNumber = (user) => {
    db.collection(COLLECTIONS.USER).doc(user.uid).update(
        {
            addedIntoCart: 0,
            last_time_added_to_cart: firebase.firestore.Timestamp.now(),
        }
    ).then((data) => {

    }).catch(function (error) {
        // console.log("Error: ", error);
    });
}

const updateRemainSlot = (user, addedNumber) => {
    let lastTime = user.last_time_added_to_cart;
    let added = (user.addedIntoCart ? user.addedIntoCart : 0)+addedNumber;
    console.log("added ===========: ", added);
    if (lastTime) {
        //Get current day
        const lastDay = lastTime.toDate();
        const currentDay = firebase.firestore.Timestamp.now().toDate();

        //Count remain
        if (sameDay(lastDay, currentDay)===false) {
            added=addedNumber
        }
    }
    db.collection(COLLECTIONS.USER).doc(user.uid).update(
        {
            addedIntoCart: added,
            last_time_added_to_cart: firebase.firestore.Timestamp.now(),
        }
    ).then((data) => {

    }).catch(function (error) {
        // console.log("Error: ", error);
    });
}

const addToCart = (part, count, user, onAddSuccess) => {
    db.collection(COLLECTIONS.USER).doc(user.uid)
        .collection(COLLECTIONS.SUB_SHOPPING_CART).doc(part.code).set(
            {
                code: part.code,
                ref_code: part.ref_code,
                des_en: part.des_en,
                des_vn: part.des_vn,
                old_code: part.old_code ? part.old_code : "",
                out_date: part.out_date,
                replaced: part.replaced ? part.replaced : [],
                price_thai: part.price_thai,
                price_vn: part.price_vn,
                price_retail: parseInt(part.price_retail),
                price_brand: parseInt(part.price_brand),
                brand: part.brand,
                count: count,
                brand: part.brand,
                bike: part.bike,
                class: part.bikeClass,
                group_id: part.group_id,
                group_name: part.group_name,
                selected:true
            }
        ).then((data) => {
            updateRemainSlot(user, 1);
            onAddSuccess && onAddSuccess(part, count);
        }).catch(function (error) {
            // console.log("Error: ", error);
        });
}

const requestShip = async (user, orderItem) => {
    var result = true;
    //Found ready items
    var readyItems = [];
    var paidItems = [];
    orderItem.items.forEach(item => {
        // var paidItem = { ...item };
        if (item.status == 4 && ((item.hasOwnProperty("paid") && item.paid == true) || orderItem.deposite >= orderItem.totalPrice)) {
            //Item is stored
            readyItems.push(item);
            item.status = 5;
            item.deliveried = false;
        }
        paidItems.push(item);
    })

    var pendingRequest = {
        key: orderItem.key,
        updated: firebase.firestore.Timestamp.now(),
        uid: user.uid,
        userName: user.name,
        address: (user.address ? user.address : ""),
        phone: user.phone ? user.phone : "",
        items: readyItems,
    };

    //Get pending order request
    var shipRequestRef = await db.collection(COLLECTIONS.SHIP_REQUEST).doc(orderItem.key).get();
    // console.log(shipRequestRef);

    if (shipRequestRef.exists) {
        // console.log("Doc Data: ", shipRequestRef.docs);
        var oldItems = shipRequestRef.data();
        if (oldItems.items && Array.isArray(oldItems.items)) {
            pendingRequest.items = [...oldItems.items, ...readyItems];
        }
        //Merge old request
        db.collection(COLLECTIONS.SHIP_REQUEST).doc(orderItem.key).update({ items: pendingRequest.items, updated: pendingRequest.updated });
    } else {
        pendingRequest.created = firebase.firestore.Timestamp.now();
        //Create deliver request
        await db.collection(COLLECTIONS.SHIP_REQUEST).doc(orderItem.key).set(pendingRequest);
    }

    //Update order item
    const addStatus = await db.collection(COLLECTIONS.ORDER).doc(orderItem.key).update({
        items: [...paidItems]
    });

    //Notify to Admin
    db.collection(COLLECTIONS.ADMIN_NOTI).add({
        userName: user.name,
        uid: user.uid,
        type: NAME.ADMIN_NOTI_TYPE.SHIP_REQUEST,
        objectId: orderItem.key,
        message: "Đã yêu cầu giao hàng (Đơn: " + orderItem.key.substr(0, 10) + ").",
        created: firebase.firestore.Timestamp.now()
    }).then((data) => {
    }).catch(function (error) {
        console.log("Error: ", error);
    });


    return result;
}

const payForReadyItem = async (user, orderItem) => {
    var payResult = {
        result: false,
        message: "Có lỗi xảy ra trong quá trình thanh toán, hãy thử lại sau.",
        errorCode: 0,
    }
    if (orderItem.hasOwnProperty("canPayMore") && orderItem.canPayMore == false)
        return payResult;

    var totalPrice = 0;

    //Found ready items
    var readyItems = [];
    var paidItems = [];
    orderItem.items.forEach(item => {
        var paidItem = { ...item };
        if (item.status >= 4 && ((item.hasOwnProperty("paid") && item.paid == false) || item.hasOwnProperty("paid") == false)) {
            //Item is stored
            totalPrice += item.count * (item.price_brand ? item.price_brand : item.price_retail)/2;
            readyItems.push(item);
            paidItem.paid = true;
        }
        paidItems.push(paidItem);
    })

    if (totalPrice > (orderItem.totalPrice - orderItem.deposite))
        totalPrice = orderItem.totalPrice - orderItem.deposite;

    //Update order item
    const addStatus = await db.collection(COLLECTIONS.ORDER).doc(orderItem.key).update({
        deposite: orderItem.deposite + totalPrice,
        items: [...paidItems],
        canPayMore: false
    });

    const oldMoney = user.money ? user.money : 0;
    const newMoney = oldMoney - totalPrice;

    //Save new user's money
    db.collection(COLLECTIONS.USER).doc(user.uid).update({
        money: newMoney
    }).then((data) => {
        //Update user money (client)
        user.money = newMoney;
    }).catch(function (error) {
        console.log("Error: ", error);
    });

    //Update user's money exchange history
    var money_history = {
        exchange_type: NAME.EXCHANGE_TYPE.PAY,
        amount: totalPrice,
        created: firebase.firestore.Timestamp.now(),
        before: oldMoney,
        after: newMoney,
        content: "Thanh toán hàng mới về của đơn hàng: " + orderItem.key.substr(0, 10),
        order_id: orderItem.key,
    };
    var old_history = user.money_history ? user.money_history : [];
    db.collection(COLLECTIONS.USER).doc(user.uid).update({
        money_history: [money_history, ...old_history],
    })

    //Notify to Admin
    db.collection(COLLECTIONS.ADMIN_NOTI).add({
        userName: user.name,
        uid: user.uid,
        type: NAME.ADMIN_NOTI_TYPE.PAID,
        objectId: orderItem.key,
        message: "Đã thanh toán thêm " + Price.convertPrice(totalPrice) + " VND cho đơn hàng " + orderItem.key.substr(0, 10),
        created: firebase.firestore.Timestamp.now()
    }).then((data) => {
    }).catch(function (error) {
        console.log("Error: ", error);
    });

    return payResult;
    // } 
    // else {
    //     //Not enough money
    //     payResult.errorCode="1",
    //     payResult.message="Tài khoản của bạn không đủ để thanh toán, hãy nạp thêm tiền"
    // }
}

export {
    addToCart,
    updateRemainSlot,
    startOrder,
    payForReadyItem,
    requestShip
}
