import { useEffect, useState } from 'react';
import { db, fb } from '../common/firebase/Firestore';
import firebase from 'firebase';
import * as COLLECTIONS from '../common/firebase/collections';

function useGetPageContent(page) {
    const [guidelineFull, setGuideLineFull] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (guidelineFull) {
            setContent(guidelineFull[page]);
            console.log("We had guideline: ", guidelineFull[page]);
        } else {
            db.collection(COLLECTIONS.CONFIG).doc('guideline').get().then((doc) => {
                let contentData = [];
                if (doc.exists) {
                    doc.data().page.forEach((page) => {
                        contentData.push(page);
                    });
                }
                setGuideLineFull(contentData);
            });
        }
    }, [page, guidelineFull])

    return content;
}

function useGetGuideline() {
    const [guidelineFull, setGuideLineFull] = useState(null);

    useEffect(() => {
        if (!guidelineFull) {
            db.collection(COLLECTIONS.CONFIG).doc('guideline').get().then((doc) => {
                let contentData = [];
                if (doc.exists) {
                    doc.data().page.forEach((page) => {
                        contentData.push(page);
                    });
                }
                setGuideLineFull(contentData);
            });
        }
    }, [guidelineFull])

    return guidelineFull;

}

export {
    useGetPageContent,
    useGetGuideline
}