import React, { useEffect, useState } from 'react';
import './resources/scss/App.scss';
import { BrowserRouter } from 'react-router-dom';
import LandingPage from './module/auth/LandingPage';
import useUserStatus from './hooks/UserStatus';
import logo from './logo.svg';
import img_small from './resources/bg_banner_gara_small.png';
import img_lag from './resources/bg_banner_gara.png';
import * as COLLECTIONS from './common/firebase/collections';
import { renderRoutes } from "react-router-config";
import routes from './router';
import { db } from './common/firebase/Firestore';
import './module/components/layout.scss';
import { Spin } from 'antd';

import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
    MagnifierContainer,
    MagnifierPreview,
    MagnifierZoom
} from "react-image-magnifiers";


export const UserContext = React.createContext({
    name: '',
    level: '',
    overbalance: 0,
    avatar: '',
    history: [],
    onUpdate: () => {

    }
});
export const ContentContext = React.createContext('content');
export const BrandContext = React.createContext('brand');
export const BikeContext = React.createContext('bike');
export const ClassContext = React.createContext('class');
export const GroupContext = React.createContext('group');
export const ProductContext = React.createContext('product');
export const CartContext = React.createContext({
    items: [],
    total: 0,
    deposit: 0,
    onUpdate: () => {
    },
    addItem: () => {
    }
})

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

function App(props) {
    var [isLogin, setIsLogin] = useState(false);
    const [isFinishLoading, setFinishLoading] = useState(false);
    const [user, setUser] = useState({ name: "Guest", money: 0 });
    const [textConfig, setTextConfig] = useState(null);
    const [brands, setBrands] = useState(null);
    const [bikes, setBikes] = useState(null);
    const [classes, setClasses] = useState(null);
    const [groups, setGroups] = useState([]);
    const [email, setEmail] = useState("");
    const [notice, setNotice] = useState("");
    const [cart, setCart] = useState(null);

    const validatePermission = (email) => {
        setEmail(email);
    }

    // useEffect(() => {
    //   if(brands && brands.length>0) {
    //     var detailBrands = brands;
    //     brands.forEach((brand) => {
    //       db.collection(COLLECTIONS.BRAND).doc(brand.key).COLLECTIONS(COLLECTIONS.SUB_BIKE).get().then((docs) => {
    //         let listBike = [];
    //         docs.forEach((doc) => {
    //           if (doc.exists) {
    //             let bike = {
    //               key: doc.id,
    //               name: doc.data().name ? doc.data().name : " ",
    //               full_name: doc.data().full_name ? doc.data().full_name : " ",
    //               description: doc.data().description ? doc.data().description : " ",
    //               brand: doc.data().brand ? doc.data().brand : " ",
    //               year: doc.data().year ? doc.data().year : " ",
    //               tag: doc.data().tag ? doc.data().tag : " ",
    //             };
    //             listBike.push(bike);
    //           }
    //         });
    //         detailBrands.bikes=listBike;
    //       });
    //     });
    //   }
    // }, [brands])

    const getServerConfig = () => {
        //Get list text config
        db.collection(COLLECTIONS.CONFIG).doc("text").get().then((doc) => {
            if (doc.exists) {
                setTextConfig(doc.data());
            }
        });
    }

    useEffect(() => {
        if (textConfig == null) {
            getServerConfig();
        }
    }, [textConfig])

    const collectData = async () => {
        //Get list Brands & push to context
        let listBrand = [];
        const brandData = await db.collection(COLLECTIONS.BRAND).get();
        //console.log("==== Brand in DB: ", brandData.docs);
        await Promise.all(brandData.docs.map(async (doc) => {
            if (doc.exists) {
                let brand = {
                    key: doc.id,
                    name: doc.data().name ? doc.data().name : " ",
                    country: doc.data().country ? doc.data().country : "Việt Nam",
                    description: doc.data().description ? doc.data().description : "des Việt Nam",
                    logo: doc.data().logo ? doc.data().logo : " ",
                    type: doc.data().type ? doc.data().type : "auth",
                };

                listBrand.push(brand);
            }
        }));
        setBrands(listBrand);

        let updatedBrand = [];
        await Promise.all(listBrand.map(async (brand) => {
            // listBrand.map(async (brand) => {
            //Get all bikes in brands
            const bikesData = await db.collection(COLLECTIONS.BRAND).doc(brand.key).collection(COLLECTIONS.SUB_BIKE).get();

            let brandExistedIndex = -1;

            // updatedBrand.push(brand);

            let listBike = [];
            await Promise.all(bikesData.docs.map(async (doc) => {
                if (doc.exists) {
                    let bikeTemp = {
                        name: doc.data().name ? doc.data().name : " ",
                        full_name: doc.data().full_name ? doc.data().full_name : " ",
                        description: doc.data().description ? doc.data().description : " ",
                        brand: doc.data().brand ? doc.data().brand : " ",
                        year: doc.data().year ? doc.data().year : " ",
                        tag: doc.data().tag ? doc.data().tag : " ",
                        key_1: doc.id,
                        key:doc.id,
                    };
                    // console.log('BIKE ID === ', doc.id);
                    // console.log('BIKE', bikeTemp);
                    const bikeClassData = await db.collection(COLLECTIONS.BRAND).doc(brand.key)
                        .collection(COLLECTIONS.SUB_BIKE).doc(doc.id)
                        .collection(COLLECTIONS.SUB_CLASS).get();

                    let listClass = [];
                    bikeClassData.docs.map((classDoc) => {
                        if (classDoc.exists) {
                            let bikeClass = {
                                key: classDoc.id,
                                logo: classDoc.data().logo ? classDoc.data().logo : " ",
                                brand: classDoc.data().brand ? classDoc.data().brand : "",
                                bike: classDoc.data().bike ? classDoc.data().bike : "",
                                name: classDoc.data().name ? classDoc.data().name : "",
                                description: classDoc.data().description ? classDoc.data().description : "",
                                year: classDoc.data().year ? classDoc.data().year : [" "],
                                tag: classDoc.data().tag ? classDoc.data().tag : [""],
                            };
                            listClass.push(bikeClass);
                        }
                    });
                    bikeTemp.classes = listClass;
                    // console.log('bikesData ID === ',  doc.id);
                    listBike.push(bikeTemp);
                    // brand.bikes = listBike;

                    //Sync new bike ASAP
                    // if(brandExisted==false)
                    // if (brandExistedIndex < 0) {
                    //     for (let i = 0; i < updatedBrand.length; i++) {
                    //         if (updatedBrand[i].key === brand.key) {
                    //             updatedBrand[i] = brand;
                    //             brandExistedIndex = i;
                    //             console.log("==== FOUND Brands 1: ", updatedBrand[i].key);
                    //         }
                    //     }
                    // } else
                    //     updatedBrand[brandExistedIndex] = brand;
                    // setBrands(updatedBrand);
                    // console.log("==== Brands 1: ", updatedBrand);
                }
            }));
            brand.bikes = listBike;
            // console.log('listBikes: ', listBike);
            updatedBrand.push(brand);
            // if (brandExistedIndex < 0) {
            //     for (let i = 0; i < updatedBrand.length; i++) {
            //         if (updatedBrand[i].key === brand.key) {
            //             updatedBrand[i] = brand;
            //             brandExistedIndex = i;
            //             console.log("==== FOUND Brands 2: ", updatedBrand[i].key);
            //         }
            //     }
            // } else
            //     updatedBrand[brandExistedIndex] = brand;
            // console.log("==== Brands 2: ", updatedBrand);
            // setBrands(updatedBrand);
        })
        );
        // console.log('listBrand', updatedBrand);
        setBrands(updatedBrand);

        //Get list Bikes & push to context
        // db.collectionGroup(COLLECTIONS.SUB_BIKE).get().then((docs) => {
        //   let listBike = [];
        //   docs.forEach((doc) => {
        //     if (doc.exists) {
        //       let bike = {
        //         key: doc.id,
        //         name: doc.data().name ? doc.data().name : " ",
        //         full_name: doc.data().full_name ? doc.data().full_name : " ",
        //         description: doc.data().description ? doc.data().description : " ",
        //         brand: doc.data().brand ? doc.data().brand : " ",
        //         year: doc.data().year ? doc.data().year : " ",
        //         tag: doc.data().tag ? doc.data().tag : " ",
        //       };
        //       listBike.push(bike);
        //     }
        //   });
        //   setBikes(listBike);
        // });

        // db.collectionGroup(COLLECTIONS.SUB_CLASS).get().then((docs) => {
        //   let listClass = [];
        //   docs.forEach((doc) => {
        //     if (doc.exists) {
        //       let bikeClass = {
        //         key: doc.id,
        //         logo: doc.data().logo ? doc.data().logo : " ",
        //         brand: doc.data().brand ? doc.data().brand : "",
        //         bike: doc.data().bike ? doc.data().bike : "",
        //         name: doc.data().name ? doc.data().name : "",
        //         description: doc.data().description ? doc.data().description : "",
        //         year: doc.data().year ? doc.data().year : [" "],
        //         tag: doc.data().tag ? doc.data().tag : [""],
        //       };
        //       listClass.push(bikeClass);
        //     }
        //   });
        //   setClasses(listClass);
        // });

        // //Get list Bikes & push to context
        // db.collectionGroup(COLLECTIONS.SUB_GROUP).get().then((docs) => {
        //   let listGroup = [];
        //   docs.forEach((doc) => {
        //     if (doc.exists) {
        //       let group = {
        //         key: doc.id,
        //         logo: doc.data().logo ? doc.data().logo : " ",
        //         brand: doc.data().brand ? doc.data().brand : "",
        //         bike: doc.data().bike ? doc.data().bike : "",
        //         bikeClass: doc.data().bikeClass ? doc.data().bikeClass : [""],
        //         name: doc.data().name ? doc.data().name : "",
        //         description: doc.data().description ? doc.data().description : "",
        //         tag: doc.data().tag ? doc.data().tag : [""],
        //       };
        //       listGroup.push(group);
        //     }
        //   });
        //   setGroups(listGroup);
        // });

    }

    const onLoginSuccess = (userInfo) => {
        // console.log("User info: ", userInfo)
        setFinishLoading(true);
        if (userInfo && userInfo.status == 1) {
            setUser(userInfo);
            setIsLogin(true);

            // console.log("1 GETTING SHOPPING CART");
            //Get shopping cart
            db.collection(COLLECTIONS.USER).doc(userInfo.uid)
                .collection(COLLECTIONS.SUB_SHOPPING_CART).onSnapshot(function (cartItem) {
                    var items = [];
                    cartItem.forEach(function (doc) {
                        items.push(doc.data());
                    });
                    setCart(items);
                    // console.log("GETTING SHOPPING CART");
                });

            //Retrive order history
            if (userInfo.order && Array.isArray(userInfo.order)) {
                let orderHistory = [];
                userInfo.order.forEach((orderId) => {
                    db.collection(COLLECTIONS.ORDER).doc(orderId).get().then((orderItem) => {
                        if (orderItem.exists && orderItem.data()) {
                            orderHistory.push({ key: orderId, ...orderItem.data() });
                        }
                        userInfo.orderHistory = orderHistory;
                        setUser(userInfo);
                    });
                })
            }

        } else {
            setNotice("Bạn không có quyền truy cập trang web này. Vui lòng liên hệ admin để biết thêm chi tiết (Hotline: 0979.598.755)");
        }
    }

    const userStatus = useUserStatus(onLoginSuccess);

    useEffect(() => {
        if (userStatus && userStatus.isLogin) {
            collectData();
        }
    }, [userStatus])

    const content = (userStatus && userStatus.isLogin) ?
        <BrowserRouter>
            <UserContext.Provider value={user}>
                <BrandContext.Provider value={brands}>
                    <BikeContext.Provider value={bikes}>
                        <ClassContext.Provider value={classes}>
                            <GroupContext.Provider value={groups}>
                                <CartContext.Provider value={cart}>
                                    {renderRoutes(routes)}
                                </CartContext.Provider>
                            </GroupContext.Provider>
                        </ClassContext.Provider>
                    </BikeContext.Provider>
                </BrandContext.Provider>
            </UserContext.Provider>
        </BrowserRouter >
        :
        <LandingPage onLoginSuccess={onLoginSuccess}></LandingPage>
    // <Login signInSuccess={validatePermission} notice={notice} />;

    const maintainance = <div style={{
        backgroundColor: "black",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <img src={logo} className="logo" style={{ width: "200px", height: "200px" }} alt="logo" />
        <span style={{ color: "white", fontSize:"5vmin" }}>Chúng tôi rất tiếc, Website đang bảo trì, vui lòng quay lại sau</span>
        <span style={{ color: "white", fontSize:"5vmin" }}>Rất xin lỗi vì sự phiền phức này, mong quý khách thông cảm</span>
    </div>

    const loadingScreen = <div style={{
        backgroundColor: "black",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }}>
        <img src={logo} className="logo" style={{ width: "200px", height: "200px" }} alt="logo" />
        <Spin style={{ margin: "50px" }} size="large" />
        <span style={{ color: "white" }}>Đang cập nhật những sản phẩm mới nhất. Xin vui lòng chờ trong giây lát...</span>
    </div>

    // const testImage = <div style={{ margin: "auto", height: "80%", background: "white", padding: "50px" }}>
    //     <PictureInPictureMagnifier
    //         imageSrc={img_small}
    //         largeImageSrc={img_lag}
    //         className="zoom_image"
    //         style={{width:"500px", height:"300px"}}
    //     />
    // </div>;

    return (
        <ErrorBoundary>
            <ContentContext.Provider value={textConfig}>
                {(userStatus != null) ? content : loadingScreen}
            </ContentContext.Provider>
            {/* {maintainance} */}
        </ErrorBoundary>
    );
}

export default App;
