import React from "react";
import { convertPrice } from "../../common/helpers/Price";

export default function Price(props) {
    const error = props.error;
    const shortFormat = props.short;
    const color = props.color ? props.color : "white";
    const unit = props.unit ? props.unit : null;
    return (
        <span className="price">
            {
                (error || props.price == 0) ?
                        <span className="price-value" style={{ color: "orangered" }}>{convertPrice(props.price ? props.price : 0, shortFormat)}</span>
                    : <span className="price-value" style={{ color: `${color}` }}>{convertPrice(props.price ? props.price : 0, shortFormat)}</span>
            }
            {
                (unit && props.price != 0) ?
                    <span className="symbol">vnd</span>
                    : null
            }
        </span>
    );
}