export const EXCHANGE_TYPE = {
    RECHARGE: "Nạp tiền",
    DEPOSITE: "Đặt cọc",
    FINISH_PAY: "Thanh toán nốt",
    PAY: "Trả tiền hàng về",
}

export const NOTI_TYPE = {
    MONEY_CHANGE: "MONEY_CHANGED",
    ORDER_STATUS_CHANGE: "ORDER_CHANGED",
}

export const ADMIN_NOTI_TYPE = {
    ORDER: "ORDER",
    PAID: "PAID",
    ASKING: "ASKING",
    SHIP_REQUEST: "SHIP_REQUEST",
}

export const USER_ROLE = {
    CUSTOMER: "Khách hàng",
    ADMIN: "Quản trị viên",
    EDITOR: "Chăm sóc nội dung",
}

export const USER_STATUS_NAME = {
    NORMAL: "Bình thường",
    REPORTING: "Đang bị báo xấu",
    DISTRUST: "Đáng nghi",
    TRUSTED: "Tin tưởng",
    BLOCKED: "Đã chặn",
    DELETED: "Đã xóa",
}

export const ORDER_STATUS_NAME = {
    NEW: "Mới",
    PROCESSING: "Đang xử lý",
    ORDERED: "Đã đặt hàng",
    WAITTING: "Chờ hàng về Việt Nam",
    STORE: "Đang nhập kho",
    DELIVERY: "Đang giao hàng",
    RECEIVED: "Đã nhận hàng",
    CUSTOMER_CANCEL: "Đơn đã bị hủy",
    REJECTED: "Đơn bị Từ chối"
}
