import React, { useContext } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import { BrandContext } from '../../App';
import { Link } from 'react-router-dom';
import * as PATH from '../../common/constant/path';
import './layout.scss';

export default function HeaderMenu(props) {

    const allBrands = useContext(BrandContext);

    const brandMenu = (
        <div className="menu-wrapper">
            <div className="menu-border">&nbsp;</div>
            <Menu theme="dark" style={{ width: "100%" }}>
                {
                    allBrands ?
                        allBrands.map((brand, key) => {
                            return (
                                <Menu.Item key={key}>
                                    <Link to={PATH.BRAND + brand.key}><span>{brand.name}</span></Link>
                                </Menu.Item>
                            );
                        })
                        : null
                }
            </Menu>
        </div>
    );

    const replacedMenu = (
        <div className="menu-wrapper">
            <div className="menu-border">&nbsp;</div>
            <Menu theme="dark">
                <Menu.Item>
                    <Link to={PATH.PROFILE}><span> Hàng chất lượng cao</span></Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={PATH.SETTING}><Icon type="setting" /><span> Fake loại 1</span></Link>
                </Menu.Item>
            </Menu>
        </div>
    );

    return (
        <div className="nav">
            <Link to={PATH.HOME}>
                <div className="home-link">TRANG CHỦ</div>
            </Link>

            <Dropdown overlay={brandMenu} overlayClassName="sub_header_menu_box">
                <div className="menu-link">
                    <span>PHỤ TÙNG CHÍNH HÃNG</span>
                    <Icon type="caret-down" style={{ paddingLeft: "2px" }} />
                </div>
            </Dropdown>

            <Link to={PATH.GUIDE_LINE}>
                <div className="home-link"> HƯỚNG DẪN </div>
            </Link>
            {/* <Dropdown overlay={replacedMenu} overlayClassName="sub_header_menu_box">
                <div className="menu-link">
                    <span>PHỤ TÙNG THAY THẾ</span>
                    <Icon type="caret-down" style={{paddingLeft: "2px"}}/>
                </div>
            </Dropdown> */}
        </div>
    );
}