import React, { useContext, useState, useEffect } from 'react';
import { Col, Icon, Row, Spin, Tabs } from 'antd';
import { CartContext, UserContext } from '../../App';
import PageUserIntro from "../components/partials/page-user-intro";
import OrderHistory from "../components/OrderHistory";
import '../../module/products/cart.scss';
import './profile.scss';
import { Link } from "react-router-dom";
import * as PATH from "../../common/constant/path";
import NumberBox from "../components/NumberBox";
import { getDate, getTime } from "../../common/helpers/Date";
import { UserLevel } from "./UserLevel";
import { convertPrice } from "../../common/helpers/Price";
import RechargeGuideLineScreen from './RechargeGuideScreen';
import MoneyHistory from '../components/MoneyHistory';

export const PROFILE_STAGE = {
    RECHARGE: "recharge",
    HISTORY: "history",
    ACCOUNT: "account",
    STORE: "store"
}

export default function Profile(props) {
    const user = useContext(UserContext);
    var screen = props.match.params.screen?props.match.params.screen:PROFILE_STAGE.HISTORY;
    const [currentStage, setCurrentStage] = useState(PROFILE_STAGE.HISTORY);

    useEffect(() => {
        if (screen && screen != currentStage) {
            setCurrentStage(screen);
        }
    }, [screen])

    const onChangeStage = (newStage) => {
        if (newStage != currentStage)
            setCurrentStage(newStage);
    }

    let profileComponent = null;
    switch (currentStage) {
        case PROFILE_STAGE.HISTORY:
            profileComponent = <OrderHistory />;
            break;
        case PROFILE_STAGE.ACCOUNT:
            profileComponent = <MoneyHistory />;
            break;
        case PROFILE_STAGE.STORE:
            profileComponent = <OrderHistory />;
            break;
        case PROFILE_STAGE.RECHARGE:
            profileComponent = <RechargeGuideLineScreen />;
            break;
    }

    return (
        <div className="cart">
            <PageUserIntro user={user} onChangeStage={onChangeStage} currentStage={currentStage} />
            <div className="container">
                {profileComponent}
            </div>
        </div>
    );
}