import React, { useContext } from 'react';
import LatestPart from '../components/latest-part/LatestParts';
import bg_banner from '../../resources/bg_banner_home.png';
import Brands from './../components/brand/Brands'
import FeatureParts from "../components/feature-part/FeatureParts";
import 'antd/dist/antd.css';
import './home.scss';
import { ContentContext } from '../../App';

export default function Home(props) {
    const config = useContext(ContentContext);

    return (
        <div className="home-page">
            <div className="cover" style={{
                backgroundImage: `url(${bg_banner})`,
            }}>&nbsp;</div>
            <div>
                <div className="container">
                    <div className="page-intro" style={{ zIndex: 99 }}>
                        <div className="title">
                            {config?config.home_title:"Empty title"}
                        </div>
                        <span className="description" style={{ marginBottom: "100px" }}>
                            {config?config.home_des:"Empty description"}
                        </span>
                    </div>
                    <Brands />
                    {/* <LatestPart/>
                    <FeatureParts /> */}
                </div>
            </div>
        </div>
    );
}
