import { useState, useEffect } from 'react';
import { fb, db } from '../common/firebase/Firestore';
import * as COLLECTIONS from '../common/firebase/collections';
// import * as NAME from '../../common/constant/name';
// import { message } from 'antd';
// import * as firebase from "firebase/app";
// import { InputField, INPUT_TYPE } from '../../objects/InputObject';
// import UIObject from '../../objects/UIObject';
// import * as Converter from '../../common/helpers/Converter';

function useGetBanks(refreshData) {
    var [bankData, setBankData] = useState(null);
    var [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refreshData && !refresh) {
            setRefresh(true);
            getData();
        } else if (bankData == null) {
            getData();
        } else setRefresh(false);
    }, [refreshData])

    const getData = () => {
        let bankRef = db.collection(COLLECTIONS.CONFIG).doc("bank");
        bankRef.get().then((doc) => {
            if (doc.exists) {
                setBankData([...doc.data().accounts ]);
                console.log("Bank data:", doc.data().accounts);
            }
            setRefresh(false);
        });
    }

    return bankData;
}

// const save = (banks, onSuccess, onFail) => {
//     try {
//         //Save bank account info
//         db.collection(COLLECTIONS.CONFIG).doc("bank").set({
//             accounts: banks
//         }).then((doc)=>{
//             onSuccess && onSuccess();
//         })

//     } catch (error) {
//         console.log(error);
//         onFail && onFail(error.name);
//     }
// }

// const deleteBank = (bank, onSuccess, onFail) => {
//     try {
//         //Save bank account info
//         db.collection(COLLECTIONS.CONFIG).doc("bank").set({
//             accounts: banks
//         }).then((doc)=>{
//             onSuccess && onSuccess();
//         })

//     } catch (error) {
//         console.log(error);
//         onFail && onFail(error.name);
//     }
// }

export {
    // BankAccount,
    useGetBanks,
    // save
}
