import React, { useState } from 'react';
import { Row, Col, Icon } from 'antd';
import ProductStyle from './product.module.css';
import * as COLOR from '../../common/constant/color';
import PartName from './PartName';
import NumberBox from '../components/NumberBox';

export default function PartItem(props) {
    const part = props.part;
    const onSelect = props.onSelect;

    const [oldSelectedNumber, setOldSelectedNumber] = useState(0);
    const [total, setTotal] = useState(part.repeat);

    const onChangeNumber = (newNumber) => {
        setTotal(newNumber);
    }

    const selectPart = () => {
        if (oldSelectedNumber != total) {
            setOldSelectedNumber(total);
            onSelect(part, total);
        }
    }

    return (
        <Row className={ProductStyle.part_item_box}>
            <Col span={3} style={{ height: "100%" }}>
                <div className={ProductStyle.part_item_col} style={{ height: "100%", fontSize: "11px" }}>{part.ref_code}</div>
            </Col>
            <Col span={14} className={ProductStyle.name_box}>
                <PartName part={part} showInventory={true}/>
            </Col>
            <Col span={4}>
                <NumberBox onChange={onChangeNumber} default={parseInt(part.repeat)?parseInt(part.repeat):0} />
            </Col>
            <Col span={3} className={ProductStyle.part_item_col} style={{ paddingLeft: "2px" }}>
                <div className={(oldSelectedNumber == total) ? ProductStyle.shopping_cart_disable : ProductStyle.shopping_cart} onClick={selectPart}><Icon type="shopping-cart" style={{ fontSize: "24px" }} /></div>
            </Col>
        </Row>
    );
}