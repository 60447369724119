import React, { Fragment } from 'react';
import { Spin } from 'antd';

export default function SelectBike(props) {
    const bikes = props.bikes;
    const onSelectBike = props.onSelectBike;
    // console.log("BIKES: ", JSON.stringify(bikes));

    return (
        <Fragment>
            {
                bikes != null ?
                    (bikes.map((bike) => {
                        return (
                            <div
                                key={bike.key}
                                onClick={() => { onSelectBike && onSelectBike(bike) }}
                                style={{ cursor: "pointer", width: "100%", margin: "10px", padding: "10px", paddingLeft: "20px", fontSize: "1.2em", backgroundColor: "#262626E6", borderRadius: "6px", height: "48px", minWidth: "200px", maxWidth: "578px" }}
                            >
                                {bike.name}
                            </div>
                        );
                    }))
                    : <Spin style={{ margin: "50px" }} size="large" />
            }
        </Fragment>
    );
}