import React from 'react';

export default function BrandBox(props) {
    const name = props.name;
    const logo = props.logo;

    return (
        <div style={{ width: "100%", color: "white" }}>
            <img src={logo} style={{
                width: "100%", border: "1px solid #707070", borderRadius: "8px"
            }} />
            <div style={{
                fontSize: '26px', textAlign: 'center', fontWeight: 'bold'
            }}>{name}</div>
        </div>
    );
}