import React, {useContext, useEffect, useState} from 'react';
import ProductBanner, {FIND_STATE} from './ProductBanner';
import {useBikeClassContext} from '../../hooks/PartHook';
import {Spin} from 'antd';
import SelectBike from './SelectBike';
import SelectBikeClass from './SelectBikeClass';
import SelectGroup from './SelectGroup';
import SelectPart from './SelectPart';
import {ProductContext} from '../../App';
import * as Converter from '../../common/helpers/Converter';

export default function ProductScreen(props) {
    var product_sku = props.match.params.sku;
    const productContext = useContext(ProductContext);

    const [selectedStage, setSelectedStage] = useState(FIND_STATE.BIKE);
    const [selectedBike, setSelectedBike] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [bikes, setBikes] = useState(null);
    const bikeClasses = useBikeClassContext(product_sku, selectedBike ? (selectedBike.key ? selectedBike.key : null) : null, productContext);

    useEffect(() => {
        if (productContext && product_sku) {
            const allBikeInBrand = Converter.getBikes(product_sku, productContext);
            setBikes(allBikeInBrand);
        }
    }, [product_sku, productContext])

    const onSelectBike = (bike) => {
        //Change current stage to select Year & bike class
        setSelectedStage(FIND_STATE.YEAR);
        setSelectedBike(bike);
        console.log("Select bike: ", bike);
    }

    const onSelectClass = (bikeClass) => {
        setSelectedStage(FIND_STATE.GROUP);
        setSelectedClass(bikeClass);
        console.log("Select class: ", bikeClass);
    }

    const onSelectGroup = (group) => {
        setSelectedStage(FIND_STATE.PART);
        setSelectedGroup(group);
        console.log("Select group: ", group);
    }

    const onSelectPart = (selectPart, total)=> {
        console.log("2 === Add to shop cart with partId: "+selectPart+" - count: "+total);
    }

    const componentByStage = (stage) => {
        switch (stage) {
            case FIND_STATE.BIKE:
                return (
                    <SelectBike bikes={bikes} onSelectBike={onSelectBike} />
                );
            case FIND_STATE.YEAR:
                return (
                    <SelectBikeClass bikeClasses={bikeClasses} onSelectClass={onSelectClass} />
                );
            case FIND_STATE.GROUP:
                return (
                    <SelectGroup bikeClass={selectedClass} bike={selectedBike} brand={product_sku} onSelectGroup={onSelectGroup} />
                );
            case FIND_STATE.PART:
                return (
                    <SelectPart group={selectedGroup} bikeClass={selectedClass} bike={selectedBike} brand={product_sku} onSelectPart={onSelectPart} />
                );
            default: return (<Spin style={{ margin: "50px" }} size="large" />);
        }
    }

    const onChangeStage = (newStage) => {
        setSelectedStage(newStage);
    }

    return (
        <div style={{ color: "white", backgroundColor: "#2C2C2C" }}>
            <ProductBanner title={product_sku} state={selectedStage} onChangeStage={onChangeStage} />

            <div style={{ width: "100%", backgroundColor: "transparent" }}>
                <div style={{ margin: "0 auto", maxWidth: "1196px", paddingTop: "3vh", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "flex-start" }}>
                    {componentByStage(selectedStage)}
                </div>
            </div>
        </div>
    );
}