import { Col, Row } from "antd";
import React from "react";
import GuideLineMenuItem from './GuideLineMenuItem';
import * as PATH from '../../common/constant/path';

export default function GuideLineMenu(props) {
    const menu = props.menu;
    return (
        <div style={{ width: "100%", backgroundColor: "transparent" }}>
            <div style={{
                margin: "0 auto",
                maxWidth: "1196px",
                paddingTop: "3vh",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start"
            }}>
                {
                    menu ?
                        menu.map(menuItem => {
                            return (
                                <GuideLineMenuItem itemId={menuItem.index} to={PATH.GUIDE_LINE + menuItem.id} title={menuItem.title} />
                            );
                        }
                        ) : null
                }
            </div>
        </div>
    )
}
