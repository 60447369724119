import React, { Fragment, useContext, useState } from 'react';
import { Row, Col, Spin, Button, Modal, notification } from 'antd';
import logo from '../../logo.svg';
import ProductStyle from './product.module.css';
import PartItem from './PartItem';
import { useGetPart } from '../../hooks/PartHook';
import * as COLOR from '../../common/constant/color';
import { addToCart, getRemainCartSlot } from '../../hooks/ShoppingCart';
import { UserContext } from '../../App';
import * as COLLECTIONS from '../../common/firebase/collections';
import { db, fb } from '../../common/firebase/Firestore';
import '../../module/components/layout.scss';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"; 
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import * as PATH from '../../common/constant/path';
import disableScroll from 'disable-scroll';

import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
    MagnifierContainer,
    MagnifierPreview,
    MagnifierZoom
} from "react-image-magnifiers";

import {
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";

export default function SelectPart(props) {
    const user = useContext(UserContext);

    const group = props.group;
    const bikeClass = props.bikeClass;
    const bike = props.bike;
    const brand_id = props.brand;
    const parts = useGetPart(brand_id, bike, bikeClass, group);
    const [shoppingCartItem, setShoppingCartItem] = useState(null);
    const [redirectComponent, setRedirectComponent] = useState(null);
    const [modalOver, setModalOver] = useState(false);

    const addToShoppingCart = (part, count) => {
        if (getRemainCartSlot(user) <= 0) {
            setModalOver(true);
        } else {
            console.log("1. Add to shop cart: partId " + part.code + " - count: " + count, user);
            addToCart(part, count, user, openNotification);
        }
    }

    const gotoShoppingCart = () => {
        console.log('Notification Clicked!');
        setRedirectComponent(<Redirect to={PATH.CART+brand_id+"/"+bike.key+"/"+bikeClass.key+"/"+group.key} />);
    }

    const openNotification = (part, count) => {
        if (part && count)
            notification.open({
                message: 'Thêm vào giỏ hàng thành công',
                description:
                    'Bạn đã thêm ' + count + ' sản phẩm ' + part.name + " [" + part.code + "] vào giỏ hàng. Click vào đây để xem giỏ hàng của bạn!",
                onClick: () => {
                    gotoShoppingCart();
                },
            });
        console.log("2. DONE SHOW NOTIFICATION");
        setShoppingCartItem(null);
    };

    const goToProfile = () => {
        setRedirectComponent(<Redirect to={PATH.ORDER} />);
    }
    const onZoomOn=()=>{
        disableScroll.on();
    }
    const onZoomOut=()=>{
        disableScroll.off();
    }

    return (
        <Fragment>
            <div onMouseEnter={onZoomOn} onMouseLeave={onZoomOut} style={{zIndex:"1", margin:"10px", flex: 9, minWidth: "300px", position: "-webkit-sticky", position: "sticky", top: "64px", height:"90vh", overflow:"auto", backgroundColor:"white" }}>
                {/* <div style={{ width: "100%"}}> */}
                <PinchZoomPan maxScale="5" initialScale="auto">
                    <img alt='Group Image' src={group ? (group.logo ? group.logo : logo) : logo} />
                </PinchZoomPan>
                {/* </div> */}
                {/* <PictureInPictureMagnifier
                    imageSrc={group ? (group.logo ? group.logo : logo) : logo}
                    // largeImageSrc={img_lag}
                    shadow={true}
                    previewOpacity={0.95}
                    previewSizePercentage={28}
                    style={{ width:"100%", minWith:"100%"}}
                    className="zoom_image"
                /> */}
            </div>
            {/* <img src={group ? (group.logo ? group.logo : logo) : logo} style={{ flex: 1, margin: "10px", maxWidth:"680px" }} /> */}

            <div style={{ flex: 8, padding: "10px", marginBottom: "30px", minWidth:"300px", backgroundColor:"#2C2C2C",zIndex:"3" }}>
                <div style={{ position: "-webkit-sticky", position: "sticky", top: "10px", zIndex:"99", backgroundColor:"#2C2C2C" }}>
                    <div style={{overflowX: "hidden"}}>
                        <div className={ProductStyle.group_name}>{group?group.name:"Group's Name"}</div>
                        <Row className={ProductStyle.part_header}>
                            <Col span={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: "100%" }}>
                                <span className={ProductStyle.title}>Ref</span>
                            </Col>
                            <Col span={14} className={ProductStyle.name_box}>
                                <span className={ProductStyle.title}>Tên phụ tùng</span>
                                <span className={ProductStyle.title}>Tồn kho</span>
                            </Col>
                            <Col span={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}><span className={ProductStyle.title}>Số lượng</span></Col>
                            <Col span={3}><span className={ProductStyle.title}></span></Col>
                        </Row>
                    </div>
                </div>
                {
                    parts != null ?
                        parts.map((part) => {
                            return (
                                <PartItem key={part.key} part={part} onSelect={addToShoppingCart} />
                            )
                        })
                        : <Spin style={{ margin: "50px" }} size="large" />
                }
                <div style={{ display: "flex", justifyContent: "flex-end", height: "40px", marginTop: "25px", paddingRight: "5px" }}>
                    <Button style={{ backgroundColor: `${COLOR.BASE}`, width: "200px", height: "100%", color: "white", fontWeight: "bold", fontSize: "14px", border: "none" }} onClick={gotoShoppingCart}>KIỂM TRA GIỎ HÀNG</Button>
                </div>
            </div>

            <Modal />
            <Modal
                title="CÓ LỖI XẢY RA"
                centered
                visible={modalOver}
                onCancel={() => setModalOver(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={() => setModalOver(false)}>BỎ QUA</Button>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => goToProfile()}>NÂNG CẤP</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Bạn đã hết lượt thêm sản phẩm vào giỏ hàng. </span>
                <span>Hãy liên hệ Admin để nâng cấp lên tài khoản. Tài khoản cấp độ cao hơn sẽ cho phép thêm nhiều sản phẩm vào giỏ hàng hơn </span>
            </Modal>
            {redirectComponent}
        </Fragment>
    );
}