import Layout from './module/components/Layout';
import Home from './module/home/Home';
import BrandScreen from './module/products/BrandScreen';
import SearchResult from './module/products/SearchResults';
import * as PATH from './common/constant/path';
import ProductScreen from './module/products/ProductScreen';
import CartScreen from './module/products/CartScreen';
import Profile from "./module/auth/Profile";
import Setting from "./module/auth/Setting";
import GuideLine from "./module/guideline/GuidelineScreen";

const routes = [
    {
        component: Layout,
        routes: [
            {
                path: PATH.HOME,
                exact: true,
                component: Home
            },
            {
                path: PATH.BRAND+":brand_id?/:bike?/:bikeClass?/:group?",
                component: BrandScreen
            },
            {
                path: PATH.SEARCH+":search_text",
                component: SearchResult
            },
            {
                path: PATH.PRODUCT+":sku",
                component: ProductScreen
            },
            {
                path: PATH.CART+":brand_id?/:bike?/:bikeClass?/:group?",
                component: CartScreen
            },
            {
                path: PATH.PROFILE+":screen",
                component: Profile
            },
            {
                path: PATH.GUIDE_LINE+":page?",
                component: GuideLine
            },
            {
                path: PATH.SETTING,
                component: Setting
            },
        ]
    }
];

export default routes;