import {useEffect, useState} from 'react';
import {db, fb} from '../common/firebase/Firestore';
import md5 from 'md5';
import firebase from 'firebase';

const EMAIL_EXISTS_DIFF_CREDENTIAL = "auth/account-exists-with-different-credential";

function useEmailLogin(email, pass, onLoginSuccess) {
    var [loginStatus, setLoginStatus] = useState(null);
    var [email, setEmail] = useState(email);
    var [pass, setPass] = useState(pass);
    var [onLoginSuccess, setOnLoginSuccess] = useState(onLoginSuccess);
    // var [onLogin, setOnLogin] = useState(onLoginSuccess);

    useEffect(() => {
        if (email && pass) {
            onLogin();
        }
    }, [email, pass]);

    const onLogin = async () => {
        var loginStt = { status: true, message: "" };

        await fb.auth().signInWithEmailAndPassword(email, md5(pass)).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            console.log("Error: ", error);
            loginStt.status = false;
            loginStt.message = "Error " + errorCode + (" (" + error.name + ").");
        });

        onLoginSuccess && onLoginSuccess(email);

        setLoginStatus(loginStt);
    }

    return loginStatus;
}

function useSignUpEmail(userInfo, onSuccess, onFail) {
    const [signUpStatus, setSignUpStatus] = useState(null);
    const [info, setInfo] = useState(userInfo);

    useEffect(() => {
        if (userInfo && userInfo.email && userInfo.password && info != userInfo) {
            setInfo(userInfo);
            doSignUp(userInfo)
        }
    }, [userInfo])

    const doSignUp = async (signupInfo) => {
        let signUpStt = { status: true, message: "" };
        let success = true;

        //Create firebase account for authen
        await firebase.auth().createUserWithEmailAndPassword(signupInfo.email, md5(signupInfo.password))
            .then((userCredential) => {
                console.log("UserCredential: ", userCredential);
                //Create new User info
                if (userCredential.user) {
                    const newUser = GetLatestInfo(userCredential.user);
                    let validateInfo = { ...signupInfo };
                    if (!validateInfo.phone)
                        delete validateInfo['phone'];
                    // if (!validateInfo.address)
                    //     delete validateInfo['address'];
                    updateCustomerInfo({ ...newUser, ...validateInfo, password: md5(validateInfo.password) }, onSuccess);
                    signUpStt.message = "Đăng ký thành công";
                } else {
                    signUpStt.message = "Không khởi tạo được thông tin người dùng";
                }
                setSignUpStatus(signUpStt);

            }).catch(function (error) {
                // Handle Errors here.
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log("Exception: ", errorMessage);
                signUpStt.status = false;
                signUpStt.message = "Đăng ký không thành công: " + errorMessage;
                onFail && onFail(signUpStt);
                success = false;
                setSignUpStatus(signUpStt);
            });

        return success;
    }

    return signUpStatus;
}

function useFacebookLogin(isDefault, onLoginSuccess, onFail) {
    let [loginStatus, setLoginStatus] = useState(null);
    let [byDefault, setDefault] = useState(isDefault);
    // let [onLoginSuccess, setOnLoginSuccess] = useState(onLoginSuccess);

    useEffect(() => {
        if (isDefault) {
            setDefault(isDefault);
            let provider = new firebase.auth.FacebookAuthProvider();
            onLogin(provider);
        }
    }, [isDefault]);

    const onLogin = async (provider) => {
        let loginStt = await DoLoginWithProvider(provider, onLoginSuccess, onFail);
        setLoginStatus(loginStt);
    }

    return loginStatus;
}

function useGoogleLogin(isDefault, onLoginSuccess, onFail) {
    let [loginStatus, setLoginStatus] = useState(null);
    let [byDefault, setDefault] = useState(isDefault);
    // let [onLoginSuccess, setOnLoginSuccess] = useState(onLoginSuccess);

    useEffect(() => {
        if (isDefault) {
            setDefault(isDefault);
            let provider = new firebase.auth.GoogleAuthProvider();
            onLogin(provider);
        }
    }, [isDefault]);

    const onLogin = async (provider) => {
        let loginStt = await DoLoginWithProvider(provider, onLoginSuccess, onFail);
        setLoginStatus(loginStt);
    }

    return loginStatus;
}

const DoLoginWithProvider = async (provider, onLoginSuccess, onFail) => {
    let loginStt = { status: true, message: "" };

    await firebase.auth().signInWithPopup(provider).then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        loginStt.token = result.credential.accessToken;
        // The signed-in user info.
        loginStt.user = result.user;
        loginStt.message = "Login Success";
        const latestInfo = GetLatestInfo(result.user);
        // console.log("User: ", fb_Info);
        updateCustomerInfo(latestInfo, onLoginSuccess);

    }).catch(function (error) {
        loginStt.status = false;
        // Handle Errors here.
        loginStt.errorCode = error.code;
        loginStt.errorMessage = error.message;
        // The email of the user's account used.
        loginStt.email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        loginStt.credential = error.credential;
        if (error.code === EMAIL_EXISTS_DIFF_CREDENTIAL)
            loginStt.message = "Email '" + error.email + "' Đã được sử dụng";
        else
            loginStt.message = "Login Failed";
        console.log("Login Failed: ", error);
        onFail && onFail(loginStt);
    });

    return loginStt;
}

const GetLatestInfo = (userInfo) => {
    // User is signed in.
    const displayName = userInfo.displayName;
    const email = userInfo.email;
    const photoURL = userInfo.photoURL;
    const phoneNumber = userInfo.phoneNumber;
    const uid = userInfo.uid;

    let newInfo = { type: "customer", status: 1, address: "" };
    if (displayName)
        newInfo.name = displayName;
    if (email)
        newInfo.email = email;
    if (photoURL)
        newInfo.avatar = photoURL;
    if (uid)
        newInfo.uid = uid;
    if (phoneNumber)
        newInfo.phone = phoneNumber;

    return newInfo;
}

const updateCustomerInfo = async (userInfo, callBack) => {
    await db.collection("Users").doc(userInfo.uid).get().then((doc) => {
        if (doc.exists) {
            const newInfo = { ...userInfo, ...doc.data(), isLogin: true };
            db.collection("Users").doc(userInfo.uid).update({ ...newInfo }).then((doc) => {
                callBack && callBack(newInfo);
            });
        } else {
            db.collection("Users").doc(userInfo.uid).set({ ...userInfo, isLogin: true }).then((doc) => {
                callBack && callBack(userInfo);
            }).catch((error) => {
                console.log("Cannot found user: ", userInfo.uid);
            });
        }
    }).catch((error) => {
        console.log("Cannot get user info: ", error);
    });
}

export { useEmailLogin, useFacebookLogin, useGoogleLogin, useSignUpEmail }