import { useContext, useEffect, useState } from 'react';
import { db } from '../common/firebase/Firestore';
import * as COLLECTIONS from '../common/firebase/collections';

export default function useLatestPart() {
    let [parts] = useState(null);

    return parts;
}

function useGetPart(brand_id, bike, bikeClass, group) {
    const [parts, setParts] = useState(null);

    useEffect(() => {
        // console.log("Start get PARTS: ", "Group: "+group.key);
        // console.log("Start get PARTS: ", "bike: "+bike.key);
        // console.log("Start get PARTS: ", "Class: "+bikeClass.key);
        if (group && brand_id && bike && bikeClass) {
            const currentBikeClass = bikeClass ? bikeClass.key : group.bikeClass;
            const currentBike = bike ? bike.key : currentBikeClass.bike;
            const currentBrand = brand_id ? brand_id : currentBikeClass.brand;
            const partUrl = "/" + COLLECTIONS.BRAND + "/" + currentBrand
                + "/" + COLLECTIONS.SUB_BIKE + "/" + currentBike
                + "/" + COLLECTIONS.SUB_CLASS + "/" + currentBikeClass
                + "/" + COLLECTIONS.SUB_GROUP + "/" + group.key
                + "/" + COLLECTIONS.SUB_PART;
            db.collection(partUrl).get().then((docs) => {
                let partData = [];
                docs.forEach((doc) => {
                    if (doc.exists) {
                        var existedRefCode = partData.findIndex(p => p.ref_code == doc.data().ref_code);
                        // console.log("Existed Ref Code: ", existedRefCode);
                        if (existedRefCode != -1 && doc.data().ref_code!=0) {
                            var existedPart=partData[existedRefCode];
                            console.log("== Existed PART: ", existedPart);
                            if(doc.data().out_date && existedPart.out_date==false) {
                                //Update old code
                                existedPart.oldCode=doc.data().code; 
                                console.log("== UPDATED OLD CODE: ", existedPart.oldCode);
                            } else if(doc.data().out_date == false && existedPart.out_date==true) {
                                partData.splice(existedRefCode, 1);
                                partData.push({ key: doc.id, oldCode:existedPart.code, ...doc.data() });
                                console.log("== REPLACED OUT OF DATE CODE: ", existedPart.code);
                            }
                        } else
                            partData.push({ key: doc.id, ...doc.data() });
                    }
                })
                setParts(partData);
            });
        }
    }, [group])

    return parts;
}

function useGetGroup(brand_id, bike, bikeClass) {
    const [groups, setGroups] = useState(null);

    useEffect(() => {
        if (bikeClass) {
            const currentBike = bike ? bike.key : bikeClass.bike;
            const currentBrand = brand_id ? brand_id : bikeClass.brand;
            const groupUrl = "/" + COLLECTIONS.BRAND + "/" + currentBrand + "/" + COLLECTIONS.SUB_BIKE + "/" + currentBike + "/" + COLLECTIONS.SUB_CLASS + "/" + bikeClass.key + "/" + COLLECTIONS.SUB_GROUP;
            retriveData(groupUrl);
        }
    }, [bikeClass])

    const retriveData = async (groupUrl) => {
        db.collection(groupUrl).get().then((docs) => {
            let groupPart = [];
            docs.forEach((doc) => {
                if (doc.exists) {
                    groupPart.push({ key: doc.id, ...doc.data() });
                }
            })
            setGroups(groupPart);
        });
    }

    return groups;
}


function useBikeContext(brand_id, context) {
    const brandContext = useContext(context);
    // const [bikes, setBikes] = useState(null);
    let bikes = null;

    // useEffect(()=>{
    // console.log("****########## Brand Context: ", brandContext.length);
    // brandContext.forEach((brand) => {
    //     console.log("Brand in context: ", brand);
    // });
    // console.log("****########## brand_id: ", brand_id);
    if (brandContext && brand_id) {
        // console.log("****########## tempBrands: ", tempBrands);
        // console.log("****########## Brand Context 2: ", brandContext);
        let correctBrand = brandContext.find(brand => brand.key == brand_id);
        // console.log("Correct Brand: ", correctBrand);
        if (correctBrand && correctBrand.bikes) {
            // console.log("Brand context:  ", brandContext);
            // console.log("All bikes:  ", correctBrand.bikes);
            bikes = correctBrand.bikes;
            // setBikes(correctBrand[0].bikes);
        } else
            bikes = [];
        // setBikes([]);
    }
    // },[brand_id, brandContext])


    return bikes;
}

function useBikeClassContext(brand_id, bike_id, context) {
    const brandContext = useContext(context);
    const [bikeClasses, setBikeClasses] = useState(null);

    useEffect(() => {
        if (brandContext && brand_id) {
            // console.log("Correct Brand: ", brandContext);
            const correctBrand = brandContext.find((brand) => {
                return brand.key === brand_id
            });
            if (correctBrand && correctBrand.bikes) {
                const bikes = correctBrand.bikes;
                // console.log("Correct Brand / Bikes: ", bikes);
                const correctBike = bikes.find((bike) => {
                    return bike.key === bike_id
                });
                // console.log("Correct Bike: ", correctBike);
                if (correctBike && correctBike.classes) {
                    setBikeClasses(correctBike.classes);
                } else setBikeClasses([]);
            } else setBikeClasses([]);
        }
    }, [brand_id, bike_id, context])


    return bikeClasses;
}


function useBikes(brand_id) {
    const [bikes, setBikes] = useState(null);

    useEffect(() => {
        db.collection(COLLECTIONS.BRAND).doc(brand_id).collection(COLLECTIONS.SUB_BIKE).get().then((docs) => {
            let allBikes = [];
            docs.forEach(doc => {
                if (doc.exists)
                    allBikes.push({ key: doc.id, ...doc.data() })
            });
            setBikes(allBikes);
        }).catch((error) => {
            setBikes([]);
        });
    }, [brand_id]);

    return bikes;
}

function useBikeClass(brand_id, bike_id) {
    const [bikeClasses, setBikeClasses] = useState(null);

    useEffect(() => {
        db.collection(COLLECTIONS.BRAND).doc(brand_id).collection(COLLECTIONS.SUB_BIKE).doc(bike_id).collection(COLLECTIONS.SUB_CLASS).get().then((docs) => {
            let allBikeClasses = [];
            docs.forEach(doc => {
                if (doc.exists)
                    allBikeClasses.push({ key: doc.id, ...doc.data() })
            });
            setBikeClasses(allBikeClasses);
        }).catch((error) => {
            setBikeClasses([]);
        });
    }, [brand_id]);

    return bikeClasses;
}

function useSearchPart(searchText, limit) {
    const [results, setResults] = useState(null);

    useEffect(() => {
        // console.log("Search text:", searchText);
        if (searchText && limit > 0) {
            var searchTextParts = searchText.toLowerCase().split(' ');

            collectResult(searchTextParts[0], searchTextParts, null);
        }
    }, [searchText]);

    const collectResult = async (firstText, searchTextParts, startAfter) => {
        // console.log("text for search: ", firstText);
        let partRef = db.collectionGroup(COLLECTIONS.SUB_PART);
        if (startAfter)
            partRef = partRef.where("keywords", "array-contains", firstText).startAfter(startAfter);
        else
            partRef = partRef.where("keywords", "array-contains", firstText);

        let totalFoundParts = 0;
        let lastValidatedPart = null;
        const docs = await partRef.limit(limit).get();
        // .then((docs) => {
        // console.log("Search result:", docs);
        // console.log("Search result size:", docs.size);
        lastValidatedPart = docs.docs[docs.size - 1];
        totalFoundParts = docs.docs.length;
        let validatedPart = totalFoundParts;

        let foundParts = [];
        docs.docs.forEach((doc) => {
            if (doc.exists) {
                let validated = true;
                for (var i = 0; i < searchTextParts.length; i++) {
                    const text = searchTextParts[i];
                    if (Array.isArray(doc.data().keywords) == false || doc.data().keywords.includes(text) == false) {
                        validated = false;
                        // console.warn("text Search: ", text);
                        // console.error("keyword: ", doc.data().keywords);
                    }

                };

                if (validated) {
                    // console.log("Validated part: ", doc.data());
                    const pathPart = {
                        brand: doc.data().brand,
                        year: doc.data().year,
                        bike: doc.data().bike,
                        bikeClass: doc.data().bikeClass,
                        group: doc.data().group_name,
                        group_id: doc.data().group_id
                    };

                    //Do group part by bike
                    var existedPart = foundParts.find((p) => { return p.code === doc.id });
                    if (existedPart) {
                        // console.log("Bikes: ", existedPart.bikes);
                        //Add new bike for duplicated part code
                        if (existedPart.bikes && Array.isArray(existedPart.bikes)) {
                            if (existedPart.bikes.includes(doc.data().bike) === false)
                                existedPart.bikes.push(doc.data().bike);
                        }
                        else
                            existedPart.bikes = [doc.data().bike];

                        //Collect path of part (bike/year/class/group)
                        if (existedPart.paths && Array.isArray(existedPart.paths) && isOnePart(existedPart, doc.data()) == false)
                            existedPart.paths.push(pathPart);
                        else {
                            existedPart.paths = [pathPart];
                            // console.log("Path part: ", existedPart.paths);
                        }

                        //Update price
                        if (doc.data().price_retail > existedPart.price_retail)
                            existedPart.price_retail = doc.data().price_retail
                        if (doc.data().price_brand > existedPart.price_brand)
                            existedPart.price_brand = doc.data().price_brand
                    } else
                        foundParts.push({ key: doc.id, ...doc.data(), bikes: [doc.data().bike], paths: [pathPart] });
                }
                else {
                    validatedPart--;
                }
            }
        });

        if (validatedPart < totalFoundParts && lastValidatedPart) {
            // console.log("Collect more part after: ", lastValidatedPart);
            //Get more available data
            const morePart = await collectResult(firstText, searchTextParts, lastValidatedPart);
            foundParts.push(...morePart);
        }

        if (startAfter == null) {
            setResults(foundParts);
            // console.log("Set found part: ", foundParts);
        }

        return foundParts;
    }

    return results;
}


function useGetGroupInBikeClass(bikeClassPath, packSize) {
    const [results, setResults] = useState(null);

    useEffect(() => {
        // console.log("Start get group for bike class:", bikeClassPath);
        if (bikeClassPath && packSize > 0) {
            collectGroup(null, bikeClassPath, packSize, null);
        }
    }, [bikeClassPath]);

    const collectGroup = async (oldData, classPath, limit, startAfter) => {
        // console.log(" === Start get after: ", startAfter);
        let bikeClassRef = db.collection(classPath);
        if (startAfter)
            bikeClassRef = bikeClassRef.orderBy("key").startAfter(startAfter);
        else
            bikeClassRef = bikeClassRef.orderBy("key");

        let totalFoundGroup = 0;
        const docs = await bikeClassRef.limit(limit).get();

        // console.log("Search result:", docs);
        // console.log("Search result size:", docs.size);
        totalFoundGroup = docs.docs.length;
        var lastGroup = docs.docs[totalFoundGroup - 1];

        let foundGroups = [];
        docs.docs.forEach((doc) => {
            if (doc.exists) {
                foundGroups.push(doc.data());
            }
        });
        // console.log("Add new groups: ", foundGroups);
        //End of list
        // var alldata = [...oldData];
        if (oldData) {
            oldData = [...oldData, ...foundGroups];
            setResults(oldData);
        } else {
            oldData = foundGroups;
            setResults(foundGroups);
        }

        if (totalFoundGroup >= limit && lastGroup && lastGroup.exists) {
            // console.log("Collect more group after: ", lastGroup);
            //Get more available data
            collectGroup(oldData, classPath, limit, lastGroup);
        }

    }

    return results;
}

const canPay = (orderItem) => {
    var can = false;
    if (orderItem.hasOwnProperty("canPayMore") == false || (orderItem.hasOwnProperty("canPayMore") && orderItem.canPayMore)) {
        orderItem.items.map(item => {
            if (item.status >= 4 && ((item.hasOwnProperty("paid") && item.paid == false) || item.hasOwnProperty("paid") == false)) //Stored (Đã về kho)
                can = true;
        });
    }

    return can;
}

const canRequestShip = (orderItem) => {
    var can = false;
    if (orderItem.status != 6 && orderItem.status != 7 && orderItem.status != 8)
        orderItem.items.map(item => {
            if (item.status == 4 && ((item.hasOwnProperty("paid") && item.paid == true) || orderItem.deposite >= orderItem.totalPrice)) //Stored (Đã về kho) và đã thanh toán
                can = true;
        });

    return can;
}
const isOnePart = (part1, part2) => {
    if (part1.brand == part2.brand
        && part1.bike == part2.bike
        && part1.bikeClass == part2.bikeClass
        && part1.group_id == part2.group_id
        && part1.code == part2.code)
        return true;
    else return false;
}

export {
    useBikes,
    useLatestPart,
    useBikeClass,
    useBikeContext,
    useBikeClassContext,
    useGetGroup,
    useGetPart,
    useSearchPart,
    useGetGroupInBikeClass,
    canPay,
    canRequestShip
};