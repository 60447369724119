import React, {useState} from 'react';
import './layout.scss';

export default function NumberBox(props) {
    const onChange = props.onChange;
    const [total, setTotal] = useState((props.hasOwnProperty("default") && parseInt(props.default)>=0)? parseInt(props.default) : 0);

    const decrease = () => {
        if (total > 1) {
            const newNumber = total - 1;
            setTotal(newNumber);
            onChange && onChange(newNumber);
        }
    }
    const increase = () => {
        const newNumber = total + 1;
        setTotal(newNumber);
        onChange && onChange(newNumber);
    }

    return (
        <div style={{
            display: "flex",
            width: "100%",
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4A4A4A"
        }}>
            <div onClick={decrease} className="center_col decrease" style={{justifyContent: "center", flex: 1}}> -
            </div>
            <div className="center_col"
                 style={{justifyContent: "center", fontSize: '1.1em', fontWeight: "bold"}}> {total} </div>
            <div onClick={increase} className="center_col increase" style={{justifyContent: "center", flex: 1}}> +
            </div>
        </div>
    )
}