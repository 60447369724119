import React, { Fragment } from 'react';
import { Row, Col, Icon } from 'antd';
import ProductStyle from './product.module.css';
import * as COLOR from '../../common/constant/color';
import { isMobile } from 'react-device-detect';

export default function PartName(props) {

    const part = props.part;
    const showLocation = props.location;
    const showInventory = props.showInventory;

    const getOldCode = (part) => {
        var oldCode = null;

        if (part.code !== part.oldCode) {
            if (part.out_date)
                oldCode = part.code;
            else
                oldCode = part.oldCode;
        }

        return oldCode;
    }

    const getNewCode = (part) => {
        var newCode = part.code;

        if (part.out_date && part.hasOwnProperty("replaced") && Array.isArray(part.replaced) && part.replaced.length > 0)
            newCode = part.replaced[0];

        return newCode;
    }

    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "left", lineHeight: "14px", width:`${isMobile?"88%":"90%"}` }}>
                <div style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
                    <span className={ProductStyle.title}>{part.des_en}</span>
                    {part.des_vn?<span className={ProductStyle.title_vn}>&nbsp; ({part.des_vn})</span>:null}
                </div>
                <div style={{ display: "inline-block" }}>
                    {
                        (getOldCode(part) != null) ?
                            <span><span className={ProductStyle.old_code}>{getOldCode(part)}</span> &nbsp; </span>
                            : null
                    }
                    <span className={(getOldCode(part) != null) ? ProductStyle.new_code : ProductStyle.code}>{getNewCode(part)}</span>
                </div>
            </div>
            {
                showLocation ?
                    <Icon className={ProductStyle.part_location} type="environment" theme="filled" />
                    : null
            }
            {
                showInventory ?
                    <span className={ProductStyle.inventory} style={{ color: `${COLOR.BASE}` }}>{(parseInt(part.inventory) > 0) ? (parseInt(part.inventory)) : ""}</span>
                    : null
            }

        </Fragment>
    )
}