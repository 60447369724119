import React, {useState} from 'react';
import {Button, Form, Icon, Input} from 'antd';
import LoginStyle from './register.scss';
import {useSignUpEmail} from '../../hooks/Authentication';

function SignUpForm(props) {
    var [notice, setNotice] = useState("");
    var [disable, setDisable] = useState(false);
    const onSignUp = props.signup;
    const onCancel = props.onCancel;
    const [signUpInfo, setSignupInfo] = useState(null);

    const onSignUpFailed = (signupStatus) => {
        setDisable(false);
        if (signupStatus && signupStatus.status == false)
            setNotice(signupStatus.message);
    }

    useSignUpEmail(signUpInfo, onSignUp, onSignUpFailed);

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                setDisable(true);
                setSignupInfo(values);
            }
        });
    };

    const {getFieldDecorator} = props.form;

    return (
        <div className="login-form-container">
            <div className="form-title">Đăng ký</div>
            <Form onSubmit={handleSubmit} className="register-form">
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{required: true, message: 'Hãy nhập email dùng để đăng nhập!'}],
                    })(
                        <Input
                            prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Email đăng nhập"
                            disabled={disable}
                            className={LoginStyle.input}
                        />,
                    )}
                </Form.Item>
                <Form.Item style={{marginBottom: "10px"}}>
                    {getFieldDecorator('password', {
                        rules: [{required: true, message: 'Hãy nhập mật khẩu!'}],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            type="password"
                            placeholder="Mật khẩu"
                            disabled={disable}/>
                    )}
                </Form.Item>
                <Form.Item style={{marginBottom: "10px"}}>
                    {getFieldDecorator('retype-pass', {
                        rules: [{required: true, message: 'Hãy nhập lại mật khẩu!'}],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            type="password"
                            placeholder="Nhập lại mật khẩu"
                            disabled={disable}/>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: 'Hãy nhập họ và tên của bạn!'}],
                    })(
                        <Input
                            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Họ và tên"
                            disabled={disable}
                            className={LoginStyle.input}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('phone', {
                        rules: [{required: false, message: 'Hãy nhập số điện thoại của bạn!'}],
                    })(
                        <Input
                            prefix={<Icon type="phone" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Số điện thoại"
                            disabled={disable}
                            className={LoginStyle.input}
                        />,
                    )}
                </Form.Item>
                {/* <Form.Item style={{marginBottom: "10px"}}>
                    {getFieldDecorator('address', {
                        rules: [{required: false, message: 'Hãy nhập địa chỉ của bạn!'}],
                    })(
                        <Input
                            prefix={<Icon type="home" style={{color: 'rgba(0,0,0,.25)'}}/>}
                            placeholder="Địa chỉ"
                            disabled={disable}
                            className={LoginStyle.input}/>
                    )}
                </Form.Item> */}
                <span style={{color: "red"}}>{notice}</span>
                <Button type="success" htmlType="submit" className="login-submit" disabled={disable}>
                    ĐĂNG KÝ
                </Button>
                <a className="back-button fs-12" onClick={() => {
                    onCancel && onCancel()
                }}>Bạn đã có tài khoản</a>
            </Form>
        </div>
    );
}

const SignUpUI = Form.create({name: 'login'})(SignUpForm);
export default SignUpUI;