import React, {useState} from 'react';
import logo from '../../logo.svg';
import {Button, Col, Form, Icon, Input, Row} from 'antd';
import {fb} from '../../common/firebase/Firestore';
import md5 from 'md5';
import LoginStyle from './login.scss';

function LoginForm(props) {
    let [notice, setNotice] = useState("");
    let [disable, setDisable] = useState(false);
    const signInSuccess = props.signInSuccess;
    const onClickFB = props.signInFB;
    const onClickGG = props.signInGG;
    const onForgotPass = props.forgotPass;
    const onSignUp = props.signup;

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                onLogin(values.username, values.password, signInSuccess);
            }
        });
    };

    const onLogin = async (name, pass, callBack) => {
        setDisable(true);
        let success = true;

        await fb.auth().signInWithEmailAndPassword(name, md5(pass)).catch(function (error) {
            // Handle Errors here.
            setNotice(error.message);
            console.log("Error: ", error);
            setDisable(false);
            success = false;
        });

        callBack && callBack(name);
        return success
    }

    const {getFieldDecorator} = props.form;

    return (
        <div>
            <div className="login-form-container">
                <img className="logo" src={logo} alt="logo"/>
                <div className="title">PHỤ TÙNG</div>
                <div className="description">PHÂN KHỐI LỚN</div>
                <Form onSubmit={handleSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{required: true, message: 'Tên đăng nhập không được rỗng!'}],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder="Tên đăng nhập"
                                disabled={disable}
                                className={LoginStyle.input}
                                style={{backgroundColor:"transparent"}}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item style={{marginBottom: "10px"}}>
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: 'Mật khẩu không được rỗng!'}],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                type="password"
                                placeholder="Mật khẩu"
                                disabled={disable}/>
                        )}
                    </Form.Item>
                    <span style={{color: "red"}}>{notice}</span>
                    <Button type="success" htmlType="submit" className="login-submit" disabled={disable}>
                        ĐĂNG NHẬP
                    </Button>
                </Form>
                <Row className="links">
                    <Col span={12}>
                        <a href="#" className={LoginStyle.suppport_link} onClick={() => {
                            onForgotPass && onForgotPass()
                        }}>Quên mật khẩu?</a>
                    </Col>
                    <Col span={12} style={{textAlign: "right"}}>
                        <a href="#" className={LoginStyle.suppport_link} onClick={() => {
                            onSignUp && onSignUp()
                        }}>Bạn chưa có tài khoản?</a>
                    </Col>
                </Row>
                <Row style={{marginTop: "3vh"}}>
                    <Col span={12}>
                        <Button type="primary" className="fb-login" onClick={() => {
                            onClickFB && onClickFB()
                        }}><Icon type="facebook" theme="filled" style={{fontSize:"21px"}} /></Button>
                    </Col>
                    <Col span={12} className="gg-login">
                        <Button type="danger" onClick={() => {
                            onClickGG && onClickGG()
                        }}><Icon type="google" size="large" style={{fontSize:"21px"}} /></Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

const LoginUI = Form.create({name: 'login'})(LoginForm);
export default LoginUI;