import React, { useContext, useEffect, useState } from 'react';
import NumberBox from '../components/NumberBox';
import PartName from './PartName';
import PageIntro from "../components/partials/page-intro";
import Price from '../components/Price';
import { Link } from 'react-router-dom';
import * as PATH from '../../common/constant/path';
import * as COLOR from '../../common/constant/color';
import { Col, Icon, Row, Spin, Button, Popconfirm, message, Modal, Checkbox } from 'antd';
import './cart.scss';
import FeatureParts from "../components/feature-part/FeatureParts";
import { CartContext, UserContext } from "../../App";
import * as COLLECTIONS from '../../common/firebase/collections';
import { db, fb } from '../../common/firebase/Firestore';
import { PROFILE_STAGE } from '../auth/Profile';
import * as firebase from "firebase/app";
import * as NAME from '../../common/constant/name';
import ProductStyle from './product.module.css';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';

import {
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";
import { async } from 'q';

export default function CartScreen(props) {
    const user = useContext(UserContext);
    const shoppingCart = useContext(CartContext);
    const [totalPrice, setTotalPrice] = useState(0);
    const [modalNoPhone, setModalNoPhone] = useState(false);
    const [modalNotEnoughMoney, setModalNotEnoughMoney] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalInvalidPrice, setModalInvalidPrice] = useState(false);
    const [modalConfirmOrder, setModalConfirmOrder] = useState(false);
    const [redirectComponent, setRedirectComponent] = useState(null);
    const [isOrdering, setIsOrdering] = useState(false);
    const [invalidItems, setInvalidItem] = useState([]);

    var brand_id = props.match.params.brand_id ? props.match.params.brand_id : null;
    var bike = props.match.params.bike ? props.match.params.bike : null;
    var bikeClass = props.match.params.bikeClass ? props.match.params.bikeClass : null;
    var group = props.match.params.group ? props.match.params.group : null;

    useEffect(() => {
        if (shoppingCart) {
            countPrice(shoppingCart)
        }
    }, [shoppingCart])

    const countPrice = (shoppingCart) => {
        let price = 0;
        var invalidPriceItems = invalidItems;

        shoppingCart.forEach(cartItem => {
            if (cartItem) {
                if (cartItem.hasOwnProperty("price_brand") && cartItem.price_brand && cartItem.price_brand > 0) {
                    if(cartItem.selected)
                        price += cartItem.price_brand * cartItem.count;
                } else {
                    console.log("INVALID ITEM ======== :", cartItem.code);
                    //Invalid price
                    if(invalidPriceItems.findIndex(t=>t.code===cartItem.code)==-1)
                    invalidPriceItems.push(cartItem);
                }
            }
        });

        setInvalidItem(invalidPriceItems);
        setTotalPrice(price);
    }

    const onChangeNumber = (newNumber, product) => {
        db.collection(COLLECTIONS.USER).doc(user.uid)
            .collection(COLLECTIONS.SUB_SHOPPING_CART).doc(product.code).update({ count: newNumber })
            .then((data) => {
                // console.log("Add to cart success: ", data);
                // onAddSuccess && onAddSuccess(part, count);
            }).catch(function (error) {
                console.log("Error: ", error);
            });
    }

    const removeCartItem = (product) => {
        db.collection(COLLECTIONS.USER).doc(user.uid)
            .collection(COLLECTIONS.SUB_SHOPPING_CART).doc(product.code).delete()
            .then(() => {
                message.success("Xóa Sản phẩm [" + product.des_en + "] khỏi giỏ hàng thành công!");
            }).catch(function (error) {

                message.error("Xóa Sản phẩm [" + product.des_en + "] khỏi giỏ hàng thất bại!" + error.message);
                console.log("Error: ", error);
            });
    }

    const removeAllCartItems = async () => {
        await Promise.all(
            shoppingCart.map(async (product) => {
                console.log("Start delete Product code: ", product.code);
                const deleteStatus = await db.collection(COLLECTIONS.USER).doc(user.uid).collection(COLLECTIONS.SUB_SHOPPING_CART).doc(product.code).delete()
                    .then(() => {
                    }).catch(function (error) {
                        message.error("Xóa Sản phẩm [" + product.des_en + "] khỏi giỏ hàng thất bại!" + error.message);
                        console.log("Error: ", error);
                    });;
                console.log("Delete status: ", deleteStatus);
            })
        );
        message.success("Xóa giỏ hàng thành công!");
    }

    const validatePrice = async () => {
        setModalConfirmOrder(false);
        if (Array.isArray(invalidItems) && invalidItems.length > 0) {
            //We have item is empty price
            console.log("========= Invalid Price =========");
            const currentTime = Date.now();

            let asker = {
                uid: user.uid,
                userName: user.name,
                phone: user.phone ? user.phone : "",
                address: user.address,
                latestAsk: currentTime,
                firstAsk: currentTime,
                status: false, //Admin replied status
                totalAsk: 1
            };

            //Save to DB
            await Promise.all(
                invalidItems.map(async (invalidItem) => {
                    //define new checking Item format
                    let newCheckingItem = {
                        totalUserAsking: 1,
                        asking: [],
                        part: invalidItem,
                        key: invalidItem.code,
                        replied: false,
                        latestAsk: firebase.firestore.Timestamp.now()
                    }

                    //Get old checking history form server
                    const invalidItemOnServer = await db.collection(COLLECTIONS.CHECKING_PRICE).doc(invalidItem.code).get();
                    console.log("Invalid Data: ", invalidItemOnServer);
                    if (invalidItemOnServer.exists) {
                        const invalidItemOnServerData = invalidItemOnServer.data();
                        newCheckingItem.totalUserAsking = invalidItemOnServerData.totalUserAsking + 1;

                        //Try to find out existed asker
                        let askerExisted = false;
                        console.log("server Data: ", invalidItemOnServerData);
                        invalidItemOnServerData.asking.map((itd) => {
                            if (askerExisted) {
                                newCheckingItem.asking.push(itd);
                            } else if (itd.uid === user.uid) {
                                asker.totalAsk = itd.totalAsk + 1;
                                asker.firstAsk = itd.firstAsk;
                                askerExisted = true;
                            }
                        });
                    }
                    newCheckingItem.asking.push(asker);

                    console.log("Save checking price: ", newCheckingItem);

                    const checkingStatus = await db.collection(COLLECTIONS.CHECKING_PRICE).doc(invalidItem.code).set({
                        ...newCheckingItem
                    }).then((data) => {
                        //Update user money
                    }).catch(function (error) {
                        console.log("Error: ", error);
                    });
                    console.log("checking status: ", checkingStatus);
                })
            );

            //Show notification for end user
            setModalInvalidPrice(true);
        } else {
            console.log("============ STARTING ORDER =============");
            await startOrder();
        }
    }

    const acceptInvalidPriceCondition = async () => {
        //Make sure we closed other modal
        setModalInvalidPrice(false);

        if (Array.isArray(invalidItems) && Array.isArray(shoppingCart) && invalidItems.length != shoppingCart.length && totalPrice > 0)
            await startOrder();
    }

    const startOrder = async () => {
        if (user.money && user.money >= totalPrice / 2) {
            const oldMoney = user.money ? user.money : 0;
            setIsOrdering(true);

            //Remove invalid price item
            let finalOrder = [];
            shoppingCart.forEach(orderItem => {
                if (orderItem.hasOwnProperty("price_brand") && orderItem.price_brand && orderItem.price_brand > 0 && orderItem.selected) {
                    var item = { ...orderItem, paid: false }
                    finalOrder.push(item);
                }
            });

            //Create order
            var newOrder = {
                uid: user.uid,
                userName: user.name,
                phone: user.phone ? user.phone : "",
                created: firebase.firestore.FieldValue.serverTimestamp(),
                status: 0,
                items: finalOrder,
                totalPrice: totalPrice,
                deposite: (totalPrice / 2),
            };
            const addStatus = await db.collection(COLLECTIONS.ORDER).add(newOrder);
            //Delete all item in shopping cart
            await Promise.all(
                shoppingCart.map(async (product) => {
                    console.log("Start delete Product code: ", product.code);
                    if (product.selected===true) {
                        const deleteStatus = await db.collection(COLLECTIONS.USER).doc(user.uid).collection(COLLECTIONS.SUB_SHOPPING_CART).doc(product.code).delete()
                            .then(() => {
                                // message.success("Xóa Sản phẩm [" + product.des_en + "] khỏi giỏ hàng thành công!");
                            }).catch(function (error) {
                                message.error("Xóa Sản phẩm [" + product.des_en + "] khỏi giỏ hàng thất bại!" + error.message);
                                console.log("Error: ", error);
                            });;
                        console.log("Delete status: ", deleteStatus);
                    }
                })
            );

            setModalSuccess(true);
            console.log("Mã đơn hàng: ", addStatus.id);
            let userOrder = (user.order && Array.isArray(user.order)) ? [addStatus.id, ...user.order] : [addStatus.id];

            //Save order history
            db.collection(COLLECTIONS.USER).doc(user.uid).update({
                order: userOrder,
                money: (oldMoney - (totalPrice / 2))
            }).then((data) => {
                //Update user money
                user.money = oldMoney - (totalPrice / 2);
                //Update user order history
                user.orderHistory.push({ key: addStatus.id, ...newOrder });
                user.order = userOrder;
            }).catch(function (error) {
                console.log("Error: ", error);
            });

            //Update user's money exchange history
            var money_history = {
                exchange_type: NAME.EXCHANGE_TYPE.DEPOSITE,
                amount: (totalPrice / 2),
                created: firebase.firestore.Timestamp.now(),
                before: oldMoney,
                after: oldMoney - (totalPrice / 2),
                content: "Đặt cọc cho đơn hàng " + addStatus.id.substr(0, 10),
                order_id: addStatus.id,
            };
            var old_history = user.money_history ? user.money_history : [];
            db.collection(COLLECTIONS.USER).doc(user.uid).update({
                money_history: [money_history, ...old_history],
            })

            //Notify to Admin
            db.collection(COLLECTIONS.ADMIN_NOTI).add({
                userName: user.name,
                uid: user.uid,
                type: NAME.ADMIN_NOTI_TYPE.ORDER,
                objectId: addStatus.id,
                message: "Đã đặt hàng (Mã đơn: " + addStatus.id.substr(0, 10) + ").",
                created: firebase.firestore.Timestamp.now()
            }).then((data) => {
            }).catch(function (error) {
                console.log("Error: ", error);
            });

            setIsOrdering(false);
        } else {
            setModalNotEnoughMoney(true);
        }
    }

    const goToRechargeScreen = () => {
        setRedirectComponent(<Redirect to={PATH.PROFILE + PROFILE_STAGE.RECHARGE} />);
    }

    const goToSetting = () => {
        setRedirectComponent(<Redirect to={PATH.SETTING} />);
    }

    const validateUserPhone = () => {
        if (user.phone) {
            //Find atleast a selected item in shoping cart
            if(shoppingCart.findIndex(t=>t.selected==true)!= -1)
                setModalConfirmOrder(true);
            else
                message.warning("Hãy chọn ít nhất 1 sản phẩm để đặt hàng!")
        } else
            setModalNoPhone(true);
    }

    const continueBuy = () => {
        let backLink = PATH.HOME;
        if (brand_id) {
            backLink += "brand/" + brand_id;
            if (bike) {
                backLink += "/" + bike;
                if (bikeClass) {
                    backLink += "/" + bikeClass;
                    if (group) backLink += "/" + group;
                }
            }
        }
        setRedirectComponent(<Redirect to={backLink} />);
    }

    const goToOrderScreen = () => {
        console.log("========== MOVE TO RECHARGE SCREEN =========");
        setRedirectComponent(<Redirect to={PATH.ORDER} />);
    }

    const onChangeSelected = (e, product) => {
        db.collection(COLLECTIONS.USER).doc(user.uid)
            .collection(COLLECTIONS.SUB_SHOPPING_CART).doc(product.code).update({ selected: e.target.checked })
            .then((data) => {
                // console.log("Add to cart success: ", data);
                // onAddSuccess && onAddSuccess(part, count);
            }).catch(function (error) {
                console.log("Error: ", error);
            });
    }

    const cartItemComponent = (product, key) => {
        return (
            <Row className="cart-row" key={key} style={{ color: `${(product.price_brand == 0) ? "orangered" : "white"}` }}>
                <Col md={1} xs={2}>
                    <Checkbox checked={product.selected} onChange={(e) => onChangeSelected(e, product)} />
                </Col>
                <Col md={2} xs={4}>
                    <span className="product-ref" style={{ color: `${(product.price_brand == 0) ? "orangered" : "white"}` }}>{product.code}</span>
                </Col>
                <Col md={7} lg={7} xs={8}>
                    {/* <PartName part={product} /> */}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "left", lineHeight: "18px" }}>
                        <span className="product-title" style={{ color: `${(product.price_brand == 0) ? "orangered" : "white"}` }}>{product.des_en}</span>
                        <span className="product-title_vn" style={{ color: `${(product.price_brand == 0) ? "orangered" : "white"}` }} >{product.des_vn}</span>
                    </div>
                </Col>
                <Col md={4} xs={4}>
                    <div className="product-price">
                        <Price price={product.price_brand} unit={isMobile ? false : "vnd"} short={isMobile} />
                    </div>
                </Col>
                <Col md={3} xs={4}>
                    <NumberBox
                        onChange={(newNumber) => onChangeNumber(newNumber, product)}
                        default={product.count} />
                </Col>
                <Col md={5} xs={0}>
                    {isMobile ?
                        null
                        : <div className="product-total">
                            <Price price={product.price_brand ? (product.count * product.price_brand) : 0} unit={isMobile ? false : "vnd"} />
                        </div>
                    }
                </Col>
                <Col md={2} lg={1} xs={2} className="actions">
                    <Popconfirm
                        title="Bạn có muốn xóa sản phẩm này khỏi giỏ hàng?"
                        onConfirm={() => removeCartItem(product)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="delete" className="delete-button" theme="filled" />
                    </Popconfirm>

                </Col>
            </Row>
        );
    }

    return (
        <div className="cart">
            <PageIntro title="Giỏ hàng" />
            <div className="container">
                <Row>
                    <Col>
                        <div className="cart-top">
                            <div className="heading">Số dư khả dụng</div>
                            <p className="title">
                                <Price price={user.money ? user.money : 0} unit="VNĐ" color={COLOR.BASE} />
                            </p>
                            <Popconfirm
                                title="Bạn có muốn xóa sản phẩm này khỏi giỏ hàng?"
                                onConfirm={() => removeAllCartItems()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className="delete-all-button">Xóa tất cả</Button>
                            </Popconfirm>
                        </div>
                        <div className="cart-wrapper">
                            <div className="cart-inner">
                                <div className="cart-detail">
                                    <div className="cart-table">
                                        <Row className="cart-header">
                                            <Col md={1} xs={2}></Col>
                                            <Col md={2} xs={4}>{isMobile ? "Code" : "Mã phụ tùng"}</Col>
                                            <Col md={7} lg={8} xs={8}>
                                                <div>Tên sản phẩm</div>
                                            </Col>
                                            <Col md={4} xs={4}>{isMobile ? "Giá (VND)" : "Đơn giá"}</Col>
                                            <Col md={3} xs={4} className="text-center">{isMobile ? "SL" : "Số lượng"}</Col>
                                            <Col md={5} xs={0} className="text-right">Thành tiền</Col>
                                            <Col md={2} lg={1} xs={2}>&nbsp;</Col>
                                        </Row>
                                        {
                                            shoppingCart && shoppingCart.length > 0 ?
                                                shoppingCart.map((product, key) => {
                                                    if (product.price_brand == 0)
                                                        return (
                                                            <Tooltip
                                                                overlayStyle={{ backgroundColor: "black", color: "white", borderRadius: "5px" }}
                                                                placement="top"
                                                                title="Sản phẩm có giá biến động theo thời gian. Hãy cập nhật số điện thoại của bạn trong phần 'thông tin cá nhân'. Phụ Tùng PKL sẽ báo lại cho bạn giá mới trong thời gian sớm nhất">
                                                                {cartItemComponent(product, key)}
                                                            </Tooltip>
                                                        );
                                                    else
                                                        return (cartItemComponent(product, key));
                                                })
                                                : ((shoppingCart && shoppingCart.length == 0) ? <div style={{ color: "orange", marginTop: "20px" }}>Không có sản phẩm nào trong giỏ hàng!</div> : <Spin style={{ margin: "50px" }} size="large" />)
                                        }
                                    </div>
                                </div>
                                <div className="cart-actions">
                                    <Row style={{ borderTop: "1px solid white" }}>
                                        <Col xs={24} sm={24} md={6} lg={7} xl={8} className="notes-and-conditions">
                                            <div>
                                                <Link to="#">
                                                    <span>Các ghi chú và điều khoản</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="#">
                                                    <span>Đặt trước 50% cho mỗi đơn hàng được xử lý</span>
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={5} lg={5} xl={4} className="total-price-section">
                                            <div className="label">
                                                <span>Tổng đơn hàng</span>
                                            </div>
                                            <Price price={totalPrice} unit="VNĐ" />
                                        </Col>
                                        <Col xs={24} sm={24} md={5} lg={5} xl={4} className="total-price-section">
                                            <div className="label">
                                                <span>Số tiền cọc</span>
                                            </div>
                                            <Price price={totalPrice / 2} unit="VNĐ" />
                                        </Col>
                                        <Col xs={24} sm={24} md={6} lg={5} xl={4} className="total-price-section">
                                            <div className="label">
                                                <span>Số dư ví</span>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <Price unit="VNĐ" price={user.money ? user.money : 0} error={(user.money && (user.money < totalPrice / 2)) ? true : false} color={`${COLOR.BASE}`} />
                                                {
                                                    (user.money < totalPrice / 2) ?
                                                        <Link to={PATH.PROFILE + PROFILE_STAGE.RECHARGE}><span style={{ color: `${COLOR.BASE}`, fontStyle: "italic" }}>Nạp thêm tiền</span></Link>
                                                        : null
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={4} className="cart-buttons">
                                            <Button className="checkout-button" onClick={() => validateUserPhone()} disabled={isOrdering || shoppingCart == null || (shoppingCart.length == 0)} loading={isOrdering}>Đặt hàng</Button>
                                            <Link to={PATH.HOME}>
                                                <Button className="continue-button">Tiếp tục mua hàng</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                                <br />
                                {/* <FeatureParts /> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                title="THIẾU THÔNG TIN CÁ NHÂN"
                centered
                visible={modalNoPhone}
                onCancel={() => setModalNoPhone(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => goToSetting()}>CẬP NHẬT</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Hãy cập nhật số điện thoại của bạn để chúng tôi có thể liên hệ với bạn khi hàng về!</span>
            </Modal>
            <Modal
                title="TÀI KHOẢN KHÔNG ĐỦ"
                centered
                visible={modalNotEnoughMoney}
                onCancel={() => setModalNotEnoughMoney(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={() => setModalNotEnoughMoney(false)}>BỎ QUA</Button>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => goToRechargeScreen()}>NẠP TIỀN</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Vui lòng nạp thêm tiền vào tài khoản để thực hiện tiếp giao dịch</span>
            </Modal>
            <Modal
                title="ĐẶT HÀNG THÀNH CÔNG"
                centered
                visible={modalSuccess}
                onCancel={() => setModalSuccess(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={() => continueBuy()}>TIẾP TỤC MUA HÀNG</Button>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => goToOrderScreen()}>KIỂM TRA ĐƠN HÀNG</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Chúc mừng bạn đã đặt hàng thành công. </span>
                <span>Đơn hàng của bạn đang được xử lý. </span>
            </Modal>
            <Modal
                title="THÔNG BÁO"
                centered
                visible={modalInvalidPrice}
                onCancel={() => setModalInvalidPrice(false)}
                style={{ border: "none" }}
                bodyStyle={{ width: "100%", display: "flex", flexDirection: "column", background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        {/* <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={acceptInvalidPriceCondition}>TÔI ĐÃ HIỂU</Button> */}
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => acceptInvalidPriceCondition()}>TÔI ĐÃ HIỂU</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Trong đơn hàng của bạn có những sản phẩm giá biến động tùy theo từng thời điểm, nên chúng tôi đang để giá tạm thời là 0 vnđ. Chúng tôi sẽ liên hệ lại với bạn về giá của những sản phẩm này sau. </span>
                <span>Những sản phẩm cần kiểm tra lại giá: </span>
                <div stlye={{ marginLeft: "15px", marginTop: "10px" }}>
                    {invalidItems.map((item) => {
                        return (
                            <span key={item.code}>{item.code}: {item.des_en} ({item.des_vn})<br/></span>
                        );
                    })}
                </div>
            </Modal>

            <Modal
                title="XÁC NHẬN ĐẶT HÀNG"
                centered
                visible={modalConfirmOrder}
                onCancel={() => setModalConfirmOrder(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={() => setModalConfirmOrder(false)}>BỎ QUA</Button>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => validatePrice()}>XÁC NHẬN</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Bạn có chắc chắn muốn đặt mua những món hàng trên không?</span>
            </Modal>

            {redirectComponent}
        </div>
    )
}