import {useEffect, useState} from 'react';
import {db, fb} from '../common/firebase/Firestore';

export default function useUserStatus(onLoginSuccess, onSignOut) {
    // var [isLogin, setIsLogin] = useState(false);
    var [user, setUser] = useState(null);
    var [onLogin, setOnLogin] = useState(onLoginSuccess);

    // useEffect(()=>{
    //     if(onLoginSuccess) {
    //         console.log("=================");
    //         setOnLogin(onLoginSuccess);
    //     }
    // }, [onLoginSuccess])

    useEffect(() => {
        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                // var displayName = user.displayName;
                // var email = user.email;
                // var emailVerified = user.emailVerified;
                // var photoURL = user.photoURL;
                // var isAnonymous = user.isAnonymous;
                // var uid = user.uid;
                // var providerData = user.providerData;
                //console.log("User: ", user);
                getUserInfo(user);
                // ...
            } else {
                // User is signed out.
                //console.log("Signed out");
                // setIsLogin(false);
                setUser({isLogin:false});
                onSignOut && onSignOut();
                // ...
            }
        });
    }, [onLogin]);

    const getUserInfo = (user) => {
        db.collection("Users").doc(user.uid).onSnapshot((doc) => {
            if (doc.exists) {
                const userInfo={...user, ...doc.data(), isLogin:true};
                setUser(userInfo);
                //console.log("On login success: ", onLoginSuccess);
                onLoginSuccess && onLoginSuccess(userInfo);
            } else {
                setUser({...user, isLogin:true});
                //console.log("Cannot found user: ", user.uid);
            }
        // }).catch((error) => {
        //     setUser({...user, isLogin:true});
        //     //console.log("Cannot get user info: ", error);
        });
    }

    return user;
}
