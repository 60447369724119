import React from 'react';
import bg_banner from "../../../../resources/bg_banner_gara.png";
import './page-intro.scss';

const PageIntro = props => {
    return (
        <div className="page-intro">
            <div className="bg" style={{backgroundImage: `url(${bg_banner})`}}/>
            <div className="content container">
                <h1 className="title">{props.title}</h1>
            </div>
        </div>
    )
}

export default PageIntro;