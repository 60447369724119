import React, { useContext, useEffect, useState } from 'react';
import ProductBanner, { FIND_STATE } from './ProductBanner';
import { useBikeClassContext } from '../../hooks/PartHook';
import { Spin } from 'antd';
import SelectBike from './SelectBike';
import SelectBikeClass from './SelectBikeClass';
import SelectGroup from './SelectGroup';
import SelectPart from './SelectPart';
import { BrandContext } from '../../App';
import * as Converter from '../../common/helpers/Converter';
import { useGetGroup } from '../../hooks/PartHook';
import { db } from '../../common/firebase/Firestore';
import * as COLLECTIONS from '../../common/firebase/collections';
import { Redirect } from 'react-router-dom';
import * as PATH from '../../common/constant/path';

export default function BrandScreen(props) {
    const brandContext = useContext(BrandContext);

    var brand_id = props.match.params.brand_id;

    const [selectedStage, setSelectedStage] = useState(FIND_STATE.BIKE);
    const [selectedBike, setSelectedBike] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [redirectComponent, setRedirectComponent] = useState(null);

    const [bikes, setBikes] = useState(null);
    const bikeClasses = useBikeClassContext(brand_id, selectedBike ? (selectedBike.key ? selectedBike.key : null) : null, BrandContext);

    var bike = props.match.params.bike ? props.match.params.bike : null;
    var bikeClass = props.match.params.bikeClass ? props.match.params.bikeClass : null;
    var group = props.match.params.group ? props.match.params.group : null;

    useEffect(() => {
        if (brandContext && brand_id) {
            const allBikeInBrand = Converter.getBikes(brand_id, brandContext);
            setBikes(allBikeInBrand);
        }
    }, [brand_id, brandContext])

    useEffect(() => {
        if (bike && bikeClass && !group) setSelectedStage(FIND_STATE.GROUP);
        else if (bike && !group) setSelectedStage(FIND_STATE.YEAR);
    }, [bike, bikeClass, group]);

    // useEffect(() => {
    //     if (bike && bikes) {
    //         const existedBike = bikes.find((b) => b.key = bike);
    //         console.log("Existed bike: ", existedBike);
    //         if (existedBike)
    //             setSelectedBike(existedBike);
    //     }
    // }, [bikes, bike]);

    // useEffect(() => {
    //     if (bikeClass && bikeClasses) {
    //         const existedClass = bikeClasses.find((b) => b.key = bikeClass);
    //         console.log("Existed Class: ", existedClass);
    //         if (existedClass)
    //             setSelectedClass(existedClass);
    //     }
    // }, [bikeClasses, bikeClass]);

    useEffect(() => {
        if (bike && bikes) {
            const existedBike = bikes.find((b) => b.key == bike);
            // console.log("Existed bike: ", existedBike);
            if (existedBike)
                setSelectedBike(existedBike);
        }

        if (bikeClass && bikeClasses) {
            const existedClass = bikeClasses.find((b) => b.key == bikeClass);
            // console.log("Existed Class: ", existedClass);
            if (existedClass) {
                setSelectedClass(existedClass);
                setSelectedYear(existedClass.year);
            }
        }

        if (brand_id && bike && bikeClass && group) {
            setSelectedStage(FIND_STATE.PART);

            //Get find group
            const groupUrl = "/" + COLLECTIONS.BRAND + "/" + brand_id + "/" + COLLECTIONS.SUB_BIKE + "/" + bike + "/" + COLLECTIONS.SUB_CLASS + "/" + bikeClass + "/" + COLLECTIONS.SUB_GROUP;
            // console.log("COllectionURL: ", groupUrl);

            db.collection(groupUrl).doc(group).get().then((doc) => {
                if (doc.exists) {
                    // console.log("Group data: ", doc.data());
                    setSelectedGroup({ key: doc.id, ...doc.data() });
                }
            });
        }
    }, [group, bikeClasses, bikeClass, bikes, bike]);

    const onSelectBike = (bike) => {
        //Change current stage to select Year & bike class
        setSelectedStage(FIND_STATE.YEAR);
        setSelectedBike(bike);
        //console.log("Select bike: ", bike);
    }

    const onSelectClass = (bikeClass) => {
        setSelectedStage(FIND_STATE.GROUP);
        setSelectedClass(bikeClass);
        setSelectedYear(bikeClass.year);
        // console.log("Select class: ", bikeClass);
    }

    const onSelectGroup = (group) => {
        setSelectedStage(FIND_STATE.PART);
        setSelectedGroup(group);
        // console.log("Select group: ", group);
    }

    const onSelectPart = (part, total) => {

    }

    const componentByStage = (stage) => {
        switch (stage) {
            case FIND_STATE.BIKE:
                return (
                    <SelectBike bikes={bikes} onSelectBike={onSelectBike} />
                );
            case FIND_STATE.YEAR:
                if (bikeClasses == null)
                    return <Spin></Spin>;
                else
                    return (
                        <SelectBikeClass bikeClasses={bikeClasses} onSelectClass={onSelectClass} />
                    );
                break;
            case FIND_STATE.GROUP:
                return (
                    <SelectGroup bikeClass={selectedClass} bike={selectedBike} brand={brand_id}
                        onSelectGroup={onSelectGroup} />
                );
            case FIND_STATE.PART:
                return (
                    <SelectPart group={selectedGroup} bikeClass={selectedClass} bike={selectedBike} brand={brand_id}
                        onSelectPart={onSelectPart} />
                );
            default:
                return (<Spin style={{ margin: "50px" }} size="large" />);
        }
    }

    const onChangeStage = (newStage) => {
        switch (newStage) {
            case FIND_STATE.BIKE:
                setSelectedBike(null);
                setSelectedClass(null);
                setSelectedYear(null);
                setSelectedGroup(null);
                setRedirectComponent(<Redirect to = {PATH.BRAND+brand_id}/>);
                break;
            case FIND_STATE.YEAR:
                setSelectedClass(null);
                setSelectedYear(null);
                setSelectedGroup(null);
                setRedirectComponent(<Redirect to = {PATH.BRAND+brand_id+"/"+bike}/>);
                break;
            default: 
        }
        setSelectedStage(newStage);
    }

    const backToSelectGroup = () => {
        setSelectedStage(FIND_STATE.GROUP);
    }

    return (
        <div style={{ color: "white", backgroundColor: "#2C2C2C", height: "auto" }}>
            <ProductBanner
                title={brand_id}
                state={selectedStage}
                onChangeStage={onChangeStage}
                bike={selectedBike}
                year={selectedYear}
                partGroup={selectedGroup}
                onBack={backToSelectGroup}
            />

            <div style={{ width: "100%", backgroundColor: "transparent" }}>
                <div style={{
                    margin: "0 auto",
                    maxWidth: "1196px",
                    paddingTop: "3vh",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "flex-start"
                }}>
                    {componentByStage(selectedStage)}
                </div>
            </div>
            {redirectComponent}
        </div>
    );
}