import React, { useContext, Fragment } from 'react';
import { Col, Icon, Row, Spin, Tabs } from 'antd';
import '../../module/products/cart.scss';
import './profile.scss';
import { Link } from "react-router-dom";
import * as PATH from "../../common/constant/path";
import { getDate, getTime } from "../../common/helpers/Date";
import { convertPrice } from "../../common/helpers/Price";
import ListBank from '../components/ListBank';
import { isMobile } from 'react-device-detect';

// export const banks = [
//     {
//         logo: "/images/landing/page_4.png",
//         name: "Ngân hàng Ngoại Thương Việt Nam",
//         chi_nhanh: "Chi nhánh Đống Đa",
//         stk: "12345678912",
//         owner: "Blue Mountan"
//     },
// ];

export default function RechargeGuideLineScreen() {

    return (
        <Fragment>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ flex: "6 1" }}>
                    <div className="heading" style={{ fontWeight: "bold", fontSize: "24px", marginBottom: "20px" }}>NẠP TIỀN</div>
                    <p style={{ fontWeight: "bold", marginBottom: "20px", fontSize: "16px" }}>Cách 1: Chuyển khoản qua ngân hàng</p>
                    <p style={{ marginBottom: "20px" }}>Quý khách chuyển khoản qua ngân hàng vào các tài khoản của PhuTungXanh. Nội dung chuyển tiền ghi rõ:</p>
                    <div style={{ padding: "10px", backgroundColor: "rgba(0, 255, 0, 0.18)", borderRadius: "8px", paddingLeft: "20px", fontStyle: "italic" }}>
                        PKL <span style={{ fontWeight: "bold" }}>&lt;User đăng nhập&gt;</span> "Số điện thoại của bạn (không bắt buộc, dùng để PhuTungXanh liên hệ khi gặp sự cố)"</div>
                    <p style={{ color: "gray", fontSize: "12px", marginTop: "10px" }}>(VD: PKL bluemountain 0912834567)</p>
                    <p>Chờ đến khi số tiền trong tài khoản của bạn được nạp để tiếp tục quay lại giỏ hàng và thanh toán.</p>
                    <br />
                    <p style={{ fontWeight: "bold", marginBottom: "20px", fontSize: "16px" }}>Cách 2: Chuyển khoản qua ATM hoặc các dịch vụ khác</p>
                    <p style={{ marginBottom: "20px" }}>Quý khách hàng thực hiện chuyển khoản qua ATM hoặc các dịch vụ không có ghi chú, vui lòng liên hệ và thông báo cho bộ phận DVKH (inbox tới fanpage fb.com/phutungPKL.v hoặc Hotline: 0979946960).</p>
                    <br />
                    <p style={{ fontWeight: "bold", marginBottom: "20px", fontSize: "16px" }}>Cách 3: Nạp tiền trực tiếp</p>
                    <p style={{ marginBottom: "20px" }}>Quý khách có thể nạp tiền trực tiếp tại kho lấy hàng hoặc qua nhân viên của PhuTungXanh.vn. Để đảm bảo an toàn về mặt tài chính cho Quý khách, PhutungXanh khuyến cáo với các lần nạp có số tiền hơn 10 triệu đồng, quý khách vui lòng sử dụng hình thức chuyển khoản. Đối với hình thức nạp tiền trực tiếp, khách hàng cần đảm bảo nhân viên của PTX.vn rời đi sau khi tiền trong tài khoản đã tăng lên tương ứng với số tiền yêu cầu nạp.</p>
                </div>
                {isMobile ? null : <div style={{ flex: "1 1 40px" }}>&nbsp;</div>}
                <div style={{ flex: "4 1", minWidth: "320px" }} className="user-level">
                    <ListBank />
                </div>
            </div>
        </Fragment>
    )
}