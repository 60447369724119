import React, { Fragment } from 'react';
import BankItem from './BankItem';
import {useGetBanks} from '../../hooks/Bank';

export default function ListBank(props) {
    
    let banks=useGetBanks(false);

    return(
        <div style={{color:"white", backgroundColor:"#242424", padding:"5px", paddingTop:"30px", paddingBottom:"40px", display:"flex", flexDirection:"column"}}>
            <span style={{fontWeight:"bolder", textAlign:"center", marginBottom:"22px", fontSize:"20px"}}>TÀI KHOẢN PHỤ TÙNG XANH</span>
            {
                banks?banks.map((bank)=>{
                    return (<BankItem bankInfo={bank}/>)
                })
                :null
            }
        </div>
    );
}