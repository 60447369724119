import React from 'react';
import { Row, Col } from 'antd';
import { Link } from "react-router-dom";
import * as COLOR from '../../common/constant/color';

export default function GuideLineMenuItem(props) {
    const itemId = props.itemId;
    const itemTitle = props.title;
    const destination = props.to;
    // const onSelectMenu = props.onSelect;

    return (

        <Link to={destination}>
            <div
                key="about"
                // onClick={() => { onSelectMenu && onSelectMenu(itemId) }}
                style={{display:"flex", color:"white", cursor: "pointer", width: "95%", padding: "5px", paddingRight: "12px", height: "fit-content", minWidth: "370px" }}
            >
                    <div style={{ background: `${COLOR.BASE}`, width:"4px" }}></div>
                    <div style={{width:"42px", float:"left", backgroundColor: "#262626E6", height:"100%", padding:"6px", paddingLeft:"9px" }}><span style={{verticalAlign:"sub"}}>{itemId}.</span></div>
                    <div style={{padding:"6px", paddingLeft: "15px", marginLeft: "3px", backgroundColor: "#262626E6", height:"100%", width:"100%" }}><span style={{verticalAlign:"sub"}}>{itemTitle}</span></div>
            </div>
        </Link>
    );

}