import React from 'react';
import { useGetGroup, useGetGroupInBikeClass } from '../../hooks/PartHook';
import { Spin } from 'antd';
import logo from '../../logo.svg';
import * as COLLECTIONS from '../../common/firebase/collections';
import { imageBasePath } from '../../common/firebase/Firestore';

// import ReactScrollbar from 'react-scrollbar-js';
// var ScrollArea = require('react-scrollbar');

export default function SelectGroup(props) {
    const bikeClass = props.bikeClass;
    const bike = props.bike;
    const brand_id = props.brand;
    const onSelectGroup = props.onSelectGroup;
    // const groups = useGetGroup(brand_id, bike, bikeClass);
    // /brand/Kawasaki/bike/300cc - Z300/class/BFFA
    const bikeClassUrl = "/" + COLLECTIONS.BRAND + "/" + brand_id
        + "/" + COLLECTIONS.SUB_BIKE + "/" + bike.key
        + "/" + COLLECTIONS.SUB_CLASS + "/" + bikeClass.key
        + "/" + COLLECTIONS.SUB_GROUP;
    const groups = useGetGroupInBikeClass(bikeClassUrl, 4);
    const baseGroupThumbUrl = imageBasePath + "/" + brand_id + "/" + bike.key + "/" + bikeClass.key + "/";

    const addDefaultSrc = (ev) => {
        ev.target.src = logo;
        ev.target.style = {
            background: "transparent",
            width: "100%",
            height: "310px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "white",
            borderRadius: "4px",
            resizeMode: "contain",
            objectFit: "contain"
        };
    }

    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "left" }}>
            <span style={{
                fontSize: "3vmin",
                color: "white",
                marginBottom: "10px",
                fontWeight: "bold",
                padding: "10px"
            }}>{bikeClass ? bikeClass.name : "Class's name"}</span>
            {
                groups ?
                    // <ScrollArea
                    //     speed={0.8}
                    //     className="area"
                    //     contentClassName="content"
                    //     horizontal={false}
                    // >
                    // <ReactScrollbar style={{width:400, height:400}}>
                        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                            {
                                groups.map((group) => {
                                    const groupIdPath = (group.key.length <= 2) ? parseInt(group.key) : group.key;
                                    return (
                                        <div onClick={() => { onSelectGroup && onSelectGroup(group) }}
                                            style={{
                                                cursor: "pointer",
                                                width: "279px",
                                                height: "340px",
                                                margin: "10px",
                                                marginBottom: "30px",
                                                position: "relative"
                                            }}
                                            key={group.key}
                                        >
                                            {/* <img
                                            src={group.logo ? group.logo : logo}
                                            style={{
                                                width: "100%",
                                                height: "310px",
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                                borderColor: "white",
                                                borderRadius: "4px",
                                                resizeMode: 'contain'
                                            }}
                                        /> */}
                                            <img
                                                src={baseGroupThumbUrl + "i (" + groupIdPath + ")."+( (group.key.length === 2) ? "jpg":"png")}
                                                onError={addDefaultSrc}
                                                style={{
                                                    width: "100%",
                                                    height: "310px",
                                                    borderWidth: "1px",
                                                    borderStyle: "solid",
                                                    borderColor: "white",
                                                    borderRadius: "4px",
                                                    resizeMode: "contain",
                                                    background: "white",
                                                    objectFit: "contain"
                                                }}
                                            />
                                            <span style={{
                                                position: "absolute",
                                                bottom: 0,
                                                left: 0,
                                                fontWeight: "500"
                                            }}>{group.name}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    // </ReactScrollbar>
                    // </ScrollArea>
                    : <Spin style={{ margin: "50px" }} size="large" />
            }
        </div >
    );
}