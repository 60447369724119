import { useEffect, useState } from 'react';
import { db, fb } from '../common/firebase/Firestore';
import firebase from 'firebase';
import * as COLLECTIONS from '../common/firebase/collections';

function useGetAllNoti(user) {
    const [noti, setNoti] = useState(null);
    const uid = user.uid;

    useEffect(() => {
        if (uid) {
            db.collection(COLLECTIONS.USER).doc(uid).collection(COLLECTIONS.SUB_NOTI).orderBy('created', "desc").limit(14).onSnapshot((docs) => {
                let notiData = [];
                docs.forEach((doc) => {
                    if (doc.exists) {
                        notiData.push({ key: doc.id, ...doc.data() });
                    }
                })
                setNoti(notiData);
            });
        }
    }, [uid])

    return noti;
}

function countUnreadNoti(listNoti, lastSeen) {
    var unread = 0;
    const lastSeenValue = lastSeen ? lastSeen.seconds : 0;

    if (listNoti)
        listNoti.forEach((noti) => {
            
            // console.log("--- Last seen: ", lastSeenValue);
            // console.log("--- noti created: ", noti.created.seconds);

            if (noti.created.seconds > lastSeenValue) {
                // console.log("Last seen: ", lastSeenValue);
                // console.log("noti created: ", noti.created.seconds);
                unread++;
            }
        })
    return unread;
}

function saveLastSeeNotification(user) {
    db.collection(COLLECTIONS.USER).doc(user.uid).update(
        {
            last_seen_noti: firebase.firestore.Timestamp.now(),
        }
    ).then((data) => {

    }).catch(function (error) {
        // console.log("Error: ", error);
    });
}

export {
    useGetAllNoti,
    saveLastSeeNotification,
    countUnreadNoti
}