import React, { Fragment, useState } from 'react';
import * as COLOR from '../../common/constant/color';
import PageIntro from "../components/partials/page-intro";
import GuideLineMenu from "./GuideLineMenu";
import GuideLinePageDetail from "./GuideLinePageDetail";

import { Col, Icon, Row, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import {useGetPageContent, useGetGuideline} from '../../hooks/GuideLine';

export default function GuidelineScreen(props) {
    const page = props.match.params.page;
    
    const guideline = useGetGuideline();
    // const content = useGetPageContent(page);
    console.log(guideline);

    const pageTitle = (page && guideline) ? guideline[page].title : "Hướng dẫn";
    const pageContent = (page && guideline) ? guideline[page].content:"Cảm ơn quý khách đã đến với phutungxanh.vn";

    return (
        <div className="cart">
            <PageIntro title={pageTitle} />
            <div className="container">
                {/* <span style={{ fontSize: "5.6vmin", fontWeight: "bold", zIndex: 1000 }}>Kết quả tìm kiếm</span> */}
                {(!page) ?
                    <div style={{ color: "#FFFFFF99", fontSize: "14px", marginTop: "20px" }}>
                        Hướng dẫn sử dụng hệ thống đặt hàng của
                    <span style={{ color: `${COLOR.BASE}` }}>&nbsp; phutungxanh.vn</span>
                    </div>
                    : null
                }
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}
                    key="SearchResultPanel"
                >
                    <div key="SearchResultMain" style={{ flex: 10, marginTop: "30px", minWidth: "300px", display: "flex", flexDirection: "column", alignItems: "strengh", width: "100%" }}>
                        <GuideLinePageDetail content={pageContent} />
                        <GuideLineMenu menu={guideline} />
                        <br/>
                        <br/>
                    </div>
                    <div style={{
                        flex: "2 1 120px",
                        float: "right",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                        key="SearchResultExpand"
                    >
                        {isMobile ? null
                            : <span style={{ fontSize: "24px", letterSpacing: "0.58px", fontWeight: "bold" }}></span>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}