import React, {Fragment, useEffect, useState} from 'react';
import {Collapse, Spin} from 'antd';
import * as Converter from '../../common/helpers/Converter';
import logo from '../../logo.svg';

const { Panel } = Collapse;

export default function SelectBikeClass(props) {
    var bikeClasses = props.bikeClasses;
    const onSelectClass = props.onSelectClass;
    const [years, setYears] = useState(null);
    const [leftYears, setLeftYears] = useState(null);
    const [rightYears, setRightYears] = useState(null);

    useEffect(() => {
        if (bikeClasses) {
            // console.log("bikeClasses: ", JSON.stringify(bikeClasses));
            const yearColapsed = Converter.collapseClassByYear(bikeClasses);
            // console.log("Year collapse: ", yearColapsed);

            //Split to 2 column to display on screen
            const yearsKey = Object.keys(yearColapsed);
            const mid = Math.round(yearsKey.length / 2);
            // console.log("Year before splice: ", yearsKey);
            // console.log("mid: ", mid);
            const left = [...yearsKey].splice(0, mid);
            const right = [...yearsKey].splice(mid , yearsKey.length)

            setLeftYears(left);
            // console.log("Year after splice: ", yearsKey);
            setRightYears(right);
            // console.log("Year left: ", yearsKey.splice(0, mid));
            // console.log("Year right: ", yearsKey.splice(mid+1, yearsKey.length));

            setYears(yearColapsed);
        }
    }, [bikeClasses]);

    const yearComponent = (year) => {
        return (
            <Panel header={year} key={year} style={{ border: "none", color: "white", marginBottom: "20px", overflow: "hidden", fontSize: "1.2em", backgroundColor: "#262626E6", borderRadius: "6px", color: "white", fontWeight: "500" }}>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", flex: 1, color: "white" }}>
                    {
                        years[year] ?
                            years[year].map((bikeClass) => {
                                return (
                                    <div key={bikeClass.key} onClick={() => {onSelectClass && onSelectClass(bikeClass)}} style={{cursor:"pointer", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "10px", maxWidth: "153px", width: "32%", minWidth: "100px" }}>
                                        <img src={bikeClass.logo ? bikeClass.logo : logo} style={{ width: "100%", height: "88px", objectFit:"cover" }} />
                                        <span style={{fontSize:"1.05em", marginTop:"8px"}}>{bikeClass.name}</span>
                                    </div>
                                );
                            })
                            : <span>Khong co phien ban nao vao nam nay</span>
                    }
                </div>
            </Panel>
        );
    }

    return (
        <Fragment>
            <Collapse expandIconPosition="right" bordered={false} style={{ border: "none", marginRight: "10px", marginLeft: "10px", backgroundColor: "transparent", minWidth: "250px", flex: 1 }}>
                {
                    leftYears ?
                        leftYears.map((year) => {
                            return (
                                yearComponent(year)
                            );
                        })
                        : <Panel><Spin style={{ margin: "50px" }} size="large" /></Panel>
                }
            </Collapse>
            <Collapse expandIconPosition="right" bordered={false} style={{ border: "none", marginRight: "10px", marginLeft: "10px", backgroundColor: "transparent", minWidth: "250px", flex: 1 }}>
                {
                    rightYears ?
                        rightYears.map((year) => {
                            return (
                                yearComponent(year)
                            );
                        })
                        : <Spin style={{ margin: "50px" }} size="large" />
                }
            </Collapse>
        </Fragment>
    );
}