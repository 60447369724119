import React from 'react';
import bg_banner from "../../../../resources/bg_banner_gara.png";
import './page-intro.scss';
import { Row, Col, Tabs, Icon, Avatar, Spin } from "antd";
import { convertPrice } from '../../../../common/helpers/Price';
import TabHeading from './TabHeading';
import { PROFILE_STAGE } from '../../../auth/Profile';
import { getRemainCartSlot } from '../../../../hooks/ShoppingCart';
import { isMobile } from 'react-device-detect';

const { TabPane } = Tabs;

const PageIntro = props => {
    const user = props.user;
    const currentStage = props.currentStage;
    const onChangeStage = props.onChangeStage;

    const onTabChange = () => {

    }

    return (
        <div className="page-user-intro">
            <div className="bg" style={{ backgroundImage: `url(${bg_banner})` }} />
            <div className="content container">
                <div className="user-wrapper">
                    {
                        user != null ?
                            <div className="user-info">
                                <div>
                                    <Avatar shape="circle" icon="user" src={user.avatar} size={isMobile ? 88 : 132} />
                                </div>
                                <div className="user-detail">
                                    <span className="title">
                                        {user.name}
                                        <span className="level level1">{user.level}</span>
                                    </span>
                                    <Col className="balance">
                                        <Col span={16}>
                                            <span className="label">
                                                <Icon type="dollar" />Số dư
                                               <span className="symbol">(VND)</span>
                                            </span>
                                            <div className="price">{convertPrice(user.money ? user.money : 0)}</div>
                                        </Col>
                                        <Col span={8}>
                                            <span className="label"><Icon type="shopping-cart" />Còn lại</span>
                                            <div className="price">{convertPrice(getRemainCartSlot(user))}</div>
                                        </Col>
                                    </Col>
                                </div>
                            </div>
                            : <Spin size="small" />
                    }
                    <div className="user-tabs">
                        <TabHeading active={(currentStage == PROFILE_STAGE.HISTORY) ? true : false}
                            icon={<Icon type="history" />}
                            title="Lịch sử mua bán"
                            onClick={() => onChangeStage(PROFILE_STAGE.HISTORY)}
                        />
                        <TabHeading active={(currentStage == PROFILE_STAGE.ACCOUNT) ? true : false}
                            icon={<Icon type="account-book" />}
                            title="Biến động tài khoản"
                            onClick={() => onChangeStage(PROFILE_STAGE.ACCOUNT)}
                        />
                        <TabHeading active={(currentStage == PROFILE_STAGE.STORE) ? true : false}
                            comingSoon={true}
                            icon={<Icon type="shop" />}
                            title="Kho hàng"
                            onClick={() => onChangeStage(PROFILE_STAGE.STORE)}
                        />
                        <TabHeading active={(currentStage == PROFILE_STAGE.RECHARGE) ? true : false}
                            icon={<Icon type="wallet" />}
                            title="Nạp tiền"
                            onClick={() => onChangeStage(PROFILE_STAGE.RECHARGE)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageIntro;