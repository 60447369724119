import React from 'react';
import {isMobile} from 'react-device-detect';

export default function BankItem(props) {
    const bankInfo = props.bankInfo;
    const logoStyleDesktop = {marginRight:"5px", width:"140px", height:"88px"};
    const logoStyleMobile = {marginRight:"5px", width:"80px", height:"40px"};

    return (
        <div style={{ display: "flex", alignItems: "stretch", color:"white", padding:"12px" }}>
            <img src={(bankInfo&&bankInfo.logo)?bankInfo.logo:"/images/landing/page_4.png"} style={isMobile?logoStyleMobile:logoStyleDesktop} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "stretch" }}>
                <div style={{ fontWeight: "bold" }}>{(bankInfo && bankInfo.name) ? bankInfo.name : "Tên ngân hàng"}</div>
                <div style={{ fontStyle: "italic", color:"lightgray" }}>{(bankInfo && bankInfo.chi_nhanh) ? bankInfo.chi_nhanh : "Chi nhánh"}</div>
                <div style={{display:"flex", marginTop:"8px"}}>
                    <div style={{flex:4, display: "flex", flexDirection: "column" }}>
                        <span style={{ fontStyle: "italic", color: "gray" }}>Số tài khoản:</span>
                        <span style={{ fontStyle: "italic", color: "gray" }}>Chủ tài khoản:</span>
                    </div>

                    <div style={{flex:5, display: "flex", flexDirection: "column", marginLeft:"6px" }}>
                        <div style={{ fontWeight: "bold" }}>{(bankInfo && bankInfo.stk) ? bankInfo.stk : "Số tài khoản"}</div>
                        <div>{(bankInfo && bankInfo.owner) ? bankInfo.owner.toUpperCase() : ""}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}