import React, { useContext } from 'react';
import { BrandContext } from '../../../App';
// import logo from '../../../logo.svg';
import BrandBox from './BrandBox';
import * as PATH from '../../../common/constant/path';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

const Brands = props => {
    const logo="https://via.placeholder.com/225x140";
    const brands = useContext(BrandContext);
    const yamaha = brands ? brands.find((brand) => { return brand.key === "Yamaha" }) : null;
    const honda = brands ? brands.find((brand) => { return brand.key === "Honda" }) : null;
    const kawa = brands ? brands.find((brand) => { return brand.key === "Kawasaki" }) : null;
    const suzuki = brands ? brands.find((brand) => { return brand.key === "Suzuki" }) : null;
    const yamahaLogo = brands ? (yamaha ? (yamaha.logo ? yamaha.logo : logo) : logo) : logo;
    const kawaLogo = brands ? (kawa ? (kawa.logo ? kawa.logo : logo) : logo) : logo;
    const hondaLogo = brands ? (honda ? (honda.logo ? honda.logo : logo) : logo) : logo;
    const suzukiLogo = brands ? (suzuki ? (suzuki.logo ? suzuki.logo : logo) : logo) : logo;
    return (
        <div style={{width: '100%', marginTop:"100px"}}>
            <Row style={{ paddingBottom: "88px" }} gutter={16} type="flex">
                <Col className="gutter-row" xs={24} sm={12} lg={6}>
                    <Link to={PATH.BRAND + "Yamaha"}>
                        <BrandBox name="Yamaha" logo={yamahaLogo} />
                    </Link>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} lg={6}>
                    <Link to={PATH.BRAND + "Kawasaki"}>
                        <BrandBox name="Kawasaki" logo={kawaLogo} />
                    </Link>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} lg={6}>
                    <Link to={PATH.BRAND + "Honda"}>
                        <BrandBox name="Honda" logo={hondaLogo} />
                    </Link>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} lg={6}>
                    <Link to={PATH.BRAND + "Suzuki"}>
                        <BrandBox name="Suzuki" logo={suzukiLogo} />
                    </Link>
                </Col>
            </Row>
        </div>
    )
}

export default Brands;