import React, { useState } from 'react';
import { useSearchPart } from '../../hooks/PartHook';
import bg_baner from '../../resources/bg_banner_gara.png';
import * as COLOR from '../../common/constant/color';
import ProductStyle from './product.module.css';
import PageIntro from "../components/partials/page-intro";
import './search.scss';

import { Col, Icon, Row, Spin } from 'antd';
import PartSearchResultItem from './PartSearchResultItem';
import { isMobile } from 'react-device-detect';

export default function SearchResults(props) {
    const searchText = props.match.params.search_text;
    const result = useSearchPart(searchText, 200);

    return (

        <div className="cart">
            <PageIntro title="Kết quả tìm kiếm" />
            <div className="container">
                {/* <span style={{ fontSize: "5.6vmin", fontWeight: "bold", zIndex: 1000 }}>Kết quả tìm kiếm</span> */}
                <div style={{ color: "#FFFFFF99", fontSize: "14px", marginTop: "20px" }}>Từ khóa tìm kiếm</div>
                <div style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: `${COLOR.BASE}`,
                    letterSpacing: "0.58px",
                    height: "29px"
                }}>{searchText}</div>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}
                    key="SearchResultPanel"
                >
                    <div key="SearchResultMain" style={{ flex: 10, marginTop: "30px", minWidth: "300px", display: "flex", flexDirection: "column", alignItems: "strengh", width: "100%" }}>
                        <Row className="search-header">
                            <Col md={3} xs={4} style={{ height: "100%" }}><span className="search-header-column">Code</span> </Col>
                            <Col md={11} xs={10} style={{ height: "100%" }}><span className="search-header-column">Tên sản phẩm</span></Col>
                            {/* <Col md={4} xs={0} style={{ height: "100%" }}><span className="search-header-column">Xe</span></Col> */}
                            <Col md={2} xs={3} style={{ height: "100%" }}><span className="search-header-column" style={{ textAlign: "center", justifyContent: "center"  }}>Vị trí</span></Col>
                            <Col md={3} xs={3} style={{ height: "100%" }}><span className="search-header-column" style={{ textAlign: "center", justifyContent: "center"  }}>Tồn kho</span></Col>
                            <Col md={3} xs={0} style={{ height: "100%" }}><span className="search-header-column" style={{ textAlign: "center", justifyContent: "center"  }}>Số lượng</span></Col>
                            <Col md={2} xs={4} style={{ height: "100%" }}></Col>
                        </Row>
                        {
                            result != null ?
                                result.map((part, key) => {
                                    return (
                                        <PartSearchResultItem key={part.key} part={part} />
                                    );
                                })
                                : <Spin style={{ margin: "50px" }} size="large" />
                        }
                    </div>
                    <div style={{
                        flex: "2 1 120px",
                        float: "right",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                        key="SearchResultExpand"
                    >
                        {isMobile ? null
                            : <span style={{ fontSize: "24px", letterSpacing: "0.58px", fontWeight: "bold" }}></span>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}