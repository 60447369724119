import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as PATH from '../../common/constant/path';
import './search.scss';

export default function SearchPartGroupResult(props) {
    const part = props.part;
    // console.log("=== PART ===", part.paths);
    return (
        <Fragment>
            {
                part ?
                    part.paths.map((i, key) => {
                        return (
                            <Link to={PATH.BRAND+i.brand+"/"+i.bike+"/"+i.bikeClass.replace('/','_')+"/"+i.group_id} key={key}>
                                <span className="paths">{i.bike} {'>'} {i.year} {'>'} {i.bikeClass} {'>'} {i.group}</span>
                            </Link>
                        );
                    }) : null
            }
        </Fragment>
    )
}