import React from 'react';
import './tab-heading.scss';

const TabHeading = props => {
    // console.log(props);
    return (
        <div className={props.comingSoon ? 'tab-heading coming-soon' : props.active ? 'tab-heading active' : 'tab-heading'} onClick={props.onClick}>
            <div className="icon">{props.icon}</div>
            <div className="heading">{props.title}</div>
            {props.comingSoon ? <div className="sub-heading">Coming soon</div> : null}
        </div>
    )
}

export default TabHeading;