import React, { useState, useEffect } from 'react';
import { Icon, Input, Layout } from 'antd';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import logo from '../../logo.svg';
import './layout.scss';
import * as PATH from '../../common/constant/path';
import * as SYSTEM from '../../common/constant/system';
import 'antd/dist/antd.css';
import HeaderMenu from './HeaderMenu';
import UserHeaderInfo from './UserHeaderInfo';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

export default function LayoutScreen(props) {
    const [searchResult, setSearchResult] = useState(null);
    const [textSearchInput, setTextSearchInput] = useState(null);

    useEffect(()=>{
        if(searchResult!=null)
            setSearchResult(null);
    },[searchResult])

    // const signOut = () => {
    //   fb.auth().signOut().then(function () {
    //     // Sign-out successful.
    //     console.log("Sign-out successful");
    //   }).catch(function (error) {
    //     // An error happened.
    //     console.log("Sign-out error: ", error);
    //   });
    // }

    // const breadcrumbs = () => {
    //     let parts = props.location.pathname.split("/");
    //     const place = parts[parts.length - 1];
    //     parts = parts.slice(1, parts.length - 1);
    //
    //     return (
    //         <Breadcrumb style={{height: "100%", alignItems: "center", float: "left", display: "flex"}}>
    //             <Breadcrumb.Item>
    //                 <Icon type="home"/>
    //                 <a href={PATH.HOME}>&nbsp;&nbsp;Trang chủ </a>
    //             </Breadcrumb.Item>
    //             {parts.map(crumb)}
    //             <Breadcrumb.Item>{place}</Breadcrumb.Item>
    //         </Breadcrumb>
    //     );
    // }
    // const crumb = (part, partIndex, parts) => {
    //     const path = ['', ...parts.slice(0, partIndex + 1)].join(" / ");
    //     return (<Breadcrumb.Item><a href={path}>{part}</a></Breadcrumb.Item>);
    // }

    const onSearch = (text) => {
        console.log("Search: ", text);
        if (text) {
            console.log("Search - text: ", text);
            setSearchResult(<Redirect to={PATH.SEARCH + text} />);
        }
    }

    const onInputTextSearch = (text) => {
        setTextSearchInput(text);
    }

    // const headerMenuComponent = () => {
    //   return (<div>Header Menu</div>);
    // }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header id="header">
                <UserHeaderInfo />

                <div className="search-wrapper">
                    <div className="search-box">
                        <Input
                            placeholder="Nhập tên phụ tùng"
                            onPressEnter={(e) => onSearch(e.target.value)}
                            className="search-input"
                            onChange={(e) => onInputTextSearch(e.target.value)}
                        />
                        {searchResult}
                        <Icon type="search" className="search-icon" onClick={() => onSearch(textSearchInput)} />
                    </div>
                </div>
                <div className="header-wrapper">
                    <Link to={PATH.HOME}>
                        <img src={logo} className="headerLogo" alt="logo" />
                    </Link>
                    <HeaderMenu />
                </div>

            </Header>

            <Content style={{ marginTop: 64, backgroundColor: "#2C2C2C" }}>
                {renderRoutes(props.route.routes)}
            </Content>
            <Footer id="footer" >
                <span>PhuTungPKL.com ©2019 Created by PhuTungXanh (v.{SYSTEM.VERSION}).</span>
            </Footer>
        </Layout>
    );
}
