import React from 'react';
import * as COLOR from '../../common/constant/color';

export default function GuideLinePageDetail(props) {
    const content = props.content;

    return (
        <div>
            {
                content ?
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    : <div style={{ color: "#FFFFFF99", fontSize: "14px", marginTop: "20px" }}>
                        Hướng dẫn sử dụng hệ thống đặt hàng của 
                        <span style={{ color: `${COLOR.BASE}` }}>&nbsp; phutungxanh.vn</span>
                    </div>
            }
        </div>
    );
}