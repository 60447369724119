import React, { useState, useEffect, Fragment, useContext } from 'react';
import LoginUI from './LoginUI';
import SignUpUI from './SignUpUI';
import * as SYSTEM from '../../common/constant/system';
import { useFacebookLogin, useGoogleLogin } from '../../hooks/Authentication';
import LandingPageStyle from './landing.scss'
import { Carousel, Row, Col, Progress } from 'antd';
import { ContentContext } from '../../App';

export default function LandingPage(props) {
    const config = useContext(ContentContext);
    const [loginStatus, setLoginStatus] = useState(null);
    const [isLoginByFB, setIsLoginByFB] = useState(false);
    const [isLoginByGG, setIsLoginByGG] = useState(false);
    const [isInSignUp, setIsInSignUp] = useState(false);
    const onLoginSuccess = props.onLoginSuccess;

    let lastScrollY = 0;
    let ticking = false;
    let landing = React.createRef();

    // const onLoginSuccess = () => {
    //     console.log("On login Success");
    // }
    const onFail = () => {
        setIsLoginByFB(false);
        setIsLoginByGG(false);
    }

    const fb_login = useFacebookLogin(isLoginByFB, onLoginSuccess, onFail);
    const gg_login = useGoogleLogin(isLoginByGG, onLoginSuccess, onFail);

    const doLoginFB = () => {
        setIsLoginByFB(true);
    }

    const doLoginGG = () => {
        setIsLoginByGG(true);
    }

    const doSignUp = () => {
        console.log("SIGN");
        setIsInSignUp(true);
        window.scroll(0, 0);
    }

    const authenComponent = isInSignUp ?
        <SignUpUI onCancel={() => {
            setIsInSignUp(false)
        }} />
        : <LoginUI signInFB={doLoginFB} signInGG={doLoginGG} signup={doSignUp} />

    const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const preventDefaultForScrollKeys = (e) => {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    const disableScroll = () => {
        let landingPage = document.getElementById('landing-page');
        if (landingPage) {
            if (window.addEventListener) // older FF
                window.addEventListener('DOMMouseScroll', preventDefault, false);
            document.addEventListener('wheel', preventDefault, { passive: false }); // Disable scrolling in Chrome
            window.onwheel = preventDefault; // modern standard
            window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
            window.ontouchmove = preventDefault; // mobile
            document.onkeydown = preventDefaultForScrollKeys;
        }
    }

    const enableScroll = () => {
        let landingPage = document.getElementById('landing-page');
        if (landingPage) {
            if (window.removeEventListener)
                window.removeEventListener('DOMMouseScroll', preventDefault, false);
            document.removeEventListener('wheel', preventDefault, { passive: false }); // Enable scrolling in Chrome
            window.onmousewheel = document.onmousewheel = null;
            window.onwheel = null;
            window.ontouchmove = null;
            document.onkeydown = null;
        }
    }

    const handleScroll = (e) => {
        disableScroll();
        let current = document.getElementsByClassName("page-scroll active");
        if (e.deltaY < 0 && current && current[0] && current[0].previousSibling !== null && !ticking) {
            //up
            ticking = true;
            lastScrollY = current[0].previousSibling.offsetTop;
            current[0].previousSibling.classList.add('active');
            current[1].classList.remove('active');
            window.scroll(current[0].offsetTop, lastScrollY);
            console.log("==== SCROLL UP ====");
        } else if (e.deltaY > 0 && current && current[0] && current[0].nextSibling !== null && !ticking) {
            //down
            ticking = true;
            lastScrollY = current[0].nextSibling.offsetTop;
            current[0].nextSibling.classList.add('active');
            current[0].classList.remove('active');
            window.scroll(current[0].offsetTop, lastScrollY);
            console.log("==== SCROLL DOWN ====", lastScrollY);
        }
        setTimeout(function () {
            ticking = false;
            enableScroll();
        }, 1000);

    };

    const fanPageComponent = () => {
        return (
            <a href="https://www.facebook.com/phutungPKL.vn/">
                <div style={{ paddingLeft: "10px", paddingRight: "10px", width: "283px", height: "56px", display: "flex", justifyContent: "space-around", padding: "auto", background: "#4267B2 0% 0% no-repeat padding-box", borderRadius: "8px" }}>
                    <div style={{ border: "1px solid #FFFFFF99", borderRadius: "50%", width: "44px", height: "44px", alignSelf: "center" }}>
                        <img style={{ objectFit: "none", width: "100%", height: "100%", alignSelf: "center" }} src="/images/facebook-f-brands.svg"></img>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "10px", paddingRight: "20px", width: "70%" }}>
                        <span style={{ fontSize: "9px", color: "rgba(255,255,255,0.9)", font: "Regular 13px/15px Roboto", letterSpacing: "0px" }}>Fanpage</span>
                        <span style={{ fontWeight: "bold", color: "white", font: "Bold 16px/19px Roboto" }}>Phutungpkl</span>
                    </div>
                    <img src="/images/paper-plane-solid.svg"></img>
                </div>
            </a>
        );
    }

    useEffect(() => {
        if (config) {
            window.addEventListener('mousewheel', handleScroll, true);
            // console.log("MOVE ======== ");
        }
    }, [config]);

    return (
        <Fragment>
            <div className="landing-page" ref={landing} id="landing-page">
                <div className="fluid-container page-scroll active">
                    <div className="login-ui">
                        {/* <div className="container"> */}
                        {authenComponent}
                        {/* </div> */}
                    </div>
                    <Carousel beforeChange={(oldIndex, newIndex) => {
                        let progress = document.getElementsByClassName("carousel-progress");
                        let i;
                        for (i = 0; i < progress.length; i++) {
                            let current = progress[i];
                            current.classList.remove('carousel-progress');
                            void current.offsetWidth;
                            current.classList.add('carousel-progress');
                        }
                    }}
                        effect="fade"
                        autoplay accessibility={false}
                        dots={false}
                        autoplaySpeed={4000}
                        pauseOnHover={false}
                        pauseOnFocus={false}
                        swipeToSlide={true}>
                        <div className="carousel-item item-one">
                            <div className="container">
                                <div className="item-detail">
                                    <h3 className="number">1
                                        <span className="progress">
                                            <span className="carousel-progress"></span>
                                        </span>
                                    </h3>
                                    <h4 className="sub-title">{config ? config.landing_page1_p1_stt : ""}</h4>
                                    <h2 className="title">{config ? config.landing_page1_p1_tt : ""}</h2>
                                    <p className="description">
                                        {config ? config.landing_page1_p1_des : ""}
                                    </p>
                                    <div className="apps-download">
                                        <a href="#"><img src="/images/app-store.png" /></a>
                                        <a href="#"><img src="/images/app-playstore.png" /></a>
                                    </div>
                                    {fanPageComponent()}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item item-two">
                            <div className="container">
                                <div className="item-detail ">
                                    <h3 className="number">2
                                        <span className="progress">
                                            <span className="carousel-progress"></span>
                                        </span>
                                    </h3>
                                    <h4 className="sub-title">{config ? config.landing_page1_p2_stt : ""}</h4>
                                    <h2 className="title">{config ? config.landing_page1_p2_tt : ""}</h2>
                                    <p className="description">
                                        {config ? config.landing_page1_p2_des : ""}
                                    </p>
                                    <div className="apps-download">
                                        <a href="#"><img src="/images/app-store.png" /></a>
                                        <a href="#"><img src="/images/app-playstore.png" /></a>
                                    </div>
                                    {fanPageComponent()}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item item-three">
                            <div className="container">
                                <div className="item-detail">
                                    <h3 className="number">3
                                        <span className="progress">
                                            <span className="carousel-progress"></span>
                                        </span>
                                    </h3>
                                    <h4 className="sub-title">{config ? config.landing_page1_p3_stt : ""}</h4>
                                    <h2 className="title">{config ? config.landing_page1_p3_tt : ""}</h2>
                                    <p className="description">
                                        {config ? config.landing_page1_p3_des : ""}
                                    </p>
                                    <div className="apps-download">
                                        <a href="#"><img src="/images/app-store.png" /></a>
                                        <a href="#"><img src="/images/app-playstore.png" /></a>
                                    </div>
                                    {fanPageComponent()}
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="fluid-container page-scroll page-scroll-padding">
                    <div className="container">
                        <Row gutter={32} type="flex">
                            <Col xs={24} lg={9} className="text-white mb-4 col-text">
                                <div>
                                    <h3 className="title">{config ? config.landing_page2_tt : ""}</h3>
                                    <p className="description">{config ? config.landing_page2_des : ""}</p>
                                    <button className="btn" onClick={() => doSignUp()} style={{ zIndex: 99999 }}>Tham gia</button>
                                </div>
                            </Col>
                            <Col xs={24} lg={13} className="pl-0 pl-lg-5 col-image">
                                <img src="/images/landing/page_2.png" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="fluid-container page-scroll page-scroll-padding">
                    <div className="container">
                        <Row gutter={32} type="flex">
                            <Col xs={24} lg={12} className="pl-0 pl-lg-5 col-image">
                                <img src="/images/landing/page_3.png" />
                            </Col>
                            <Col xs={24} lg={12} className="text-white mb-4 col-text">
                                <div>
                                    <h3 className="title">{config ? config.landing_page3_tt : ""}</h3>
                                    <p className="description">{config ? config.landing_page3_des : ""}</p>
                                    <button className="btn" onClick={doSignUp}>Tham gia</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="fluid-container page-scroll page-scroll-padding">
                    <div className="container">
                        <Row gutter={32} type="flex">
                            <Col xs={24} lg={12} className="text-white mb-4 col-text">
                                <div>
                                    <h3 className="title">{config ? config.landing_page4_tt : ""}</h3>
                                    <p className="description">{config ? config.landing_page4_des : ""}</p>
                                    <button className="btn" onClick={doSignUp}>Tham gia</button>
                                </div>
                            </Col>
                            <Col xs={24} lg={12} className="pl-0 pl-lg-5 col-image">
                                <img src="/images/landing/page_4.png" />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="fluid-container page-scroll page-scroll-padding our-commit">
                    <div className="container">
                        <Row gutter={32}>
                            <Col span={24}>
                                <h2 className="title text-center">Cam kết của chúng tôi</h2>
                            </Col>
                        </Row>
                        {/* <Row gutter={32} className="mb-4">
                            <Col xs={24} md={12} lg={8} className="commit text-center mb-3">
                                <img src="/images/landing/page_4.png" />
                                <h3 className="commit-title">{config?config.comitment1_tt:""}</h3>
                                <p className="description">{config?config.comitment1_des:""}</p>
                            </Col>
                            <Col xs={24} md={12} lg={8} className="commit text-center mb-3">
                                <img src="/images/landing/page_4.png" />
                                <h3 className="commit-title">{config?config.comitment2_tt:""}</h3>
                                <p className="description">{config?config.comitment2_des:""}</p>
                            </Col>
                            <Col xs={24} md={12} lg={8} className="commit text-center mb-3">
                                <img src="/images/landing/page_4.png" />
                                <h3 className="commit-title">{config?config.comitment3_tt:""}</h3>
                                <p className="description">{config?config.comitment3_des:""}</p>
                            </Col>
                        </Row> */}
                        <div className="commit-container mb-4">
                            <div className="commit-box commit text-center mb-3">
                                <img src="/images/landing/img_1.png" />
                                <h3 className="commit-title">{config ? config.comitment1_tt : ""}</h3>
                                <p className="description">{config ? config.comitment1_des : ""}</p>
                            </div>
                            <div className="commit-box commit text-center mb-3">
                                <img src="/images/landing/img_2.png" />
                                <h3 className="commit-title">{config ? config.comitment2_tt : ""}</h3>
                                <p className="description">{config ? config.comitment2_des : ""}</p>
                            </div>
                            <div className="commit-box commit text-center mb-3">
                                <img src="/images/landing/img_3.png" />
                                <h3 className="commit-title">{config ? config.comitment3_tt : ""}</h3>
                                <p className="description">{config ? config.comitment3_des : ""}</p>
                            </div>
                        </div>
                        <Row className="footer" gutter={32}>
                            <Col span={24}>
                                <h2>Vehicle with <br /> love</h2>
                                <p>@ phutungpkl.vn</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="scroll-page">
                <div className="image-wrap">
                    <img src='/images/setting.png' />
                </div>
                <p>Scroll page</p>
            </div>
        </Fragment>
    )
}