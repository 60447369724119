import React from 'react';
import {Col, Row, Radio} from "antd";

export const UserLevel = () => (
    <div className="user-level">
        <h3 className="heading">Các cấp vip</h3>
        <Row className="title">
            <Col span={6}>Cấp vip</Col>
            <Col span={18}>Số lượng phụ tùng trog giỏ</Col>
        </Row>
        <Row>
            <Col span={6}><span className="level level1">Vip 1</span></Col>
            <Col span={18}><strong>10</strong> món / 1 ngày</Col>
        </Row>
        <Row>
            <Col span={6}><span className="level level2">Vip 2</span></Col>
            <Col span={18}><strong>50</strong> món / ngày</Col>
        </Row>
        <Row>
            <Col span={6}><span className="level level3">Vip 3</span></Col>
            <Col span={18}><strong>100</strong> món / 1 ngày</Col>
        </Row>
        {/*<Row>*/}
        {/*    <Col>*/}
        {/*        <Radio.Group>*/}
        {/*            <Radio.Button>Liên hệ với chúng tôi</Radio.Button>*/}
        {/*            <Radio.Button className="hotline">*/}
        {/*                <div>Hotline</div>*/}
        {/*                <div>091 234 5678</div>*/}
        {/*            </Radio.Button>*/}
        {/*            <Radio.Button>Large</Radio.Button>*/}
        {/*        </Radio.Group>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
    </div>
)