import React, { useContext, useEffect, useState } from 'react';
import { CartContext, UserContext } from '../../App';
import { Avatar, Badge, Dropdown, Icon, Menu, Spin, Input, Popover } from 'antd';
import './layout.scss';
import { fb } from '../../common/firebase/Firestore';
import * as PATH from '../../common/constant/path';
import { Link } from 'react-router-dom';
import * as COLOR from '../../common/constant/color';
import { PROFILE_STAGE } from '../auth/Profile';
import * as NAME from '../../common/constant/name';
import { useGetAllNoti, saveLastSeeNotification, countUnreadNoti } from '../../hooks/Notification';
import './layout.scss';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';
import { convertPrice } from '../../common/helpers/Price';

export default function UserHeaderInfo(props) {
    const user = useContext(UserContext);
    const ctxCart = useContext(CartContext);
    const [lastSeen, setLastSeen] = useState(0);
    const allNoti = useGetAllNoti(user);
    const [totalUnreadNoti, setTotalUnreadNoti] = useState(0);
    const [searchResult, setSearchResult] = useState(null);
    const [showSearchExpand, setShowSearchExpand] = useState(false);
    const [textSearchInput, setTextSearchInput] = useState(null);
    // console.log(totalUnreadNoti);

    useEffect(() => {
        if (user && user.last_seen_noti)
            setLastSeen(user.last_seen_noti);
    }, [user])

    // useEffect(() => {
    //     if(lastSeen && allNoti)
    //         setTotalUnreadNoti(countUnreadNoti(allNoti, lastSeen));
    // }, [lastSeen])

    // useEffect(() => {
    //     if(lastSeen && allNoti)
    //         setTotalUnreadNoti(countUnreadNoti(allNoti, lastSeen));
    // }, [allNoti])

    useEffect(() => {
        if(lastSeen && allNoti) {
            const unread = countUnreadNoti(allNoti, lastSeen);
            console.log("FOUND UNREAD NOTI: ", unread);
            setTotalUnreadNoti(unread);
        }
    }, [allNoti, lastSeen])

    const signOut = () => {
        fb.auth().signOut().then(function () {
            // Sign-out successful.
        }).catch(function (error) {
            // An error happened.
        });
    }

    const profileMenu = (
        <Menu>
            <Menu.Item>
                <Link to={PATH.PROFILE + PROFILE_STAGE.HISTORY}><Icon type="user" /><span>&nbsp; Lịch sử tài khoản</span></Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={PATH.SETTING}><Icon type="setting" /><span>&nbsp; Cài đặt thông tin</span></Link>
            </Menu.Item>
            <Menu.Item onClick={signOut}>
                <Icon type="logout" /><span>Đăng xuất</span>
            </Menu.Item>
        </Menu>
    );

    const getNotiLink = (notiType) => {
        let link = PATH.ORDER;
        switch (notiType) {
            case NAME.NOTI_TYPE.MONEY_CHANGE:
                link = PATH.ACCOUNT;
                break;
            case NAME.NOTI_TYPE.ORDER_STATUS_CHANGE:
                break;
        }
        return link;
    }

    const onSelect = () => {
        //Save last see notification
        saveLastSeeNotification(user);
        setLastSeen(firebase.firestore.Timestamp.now());
    }

    const notificationList = (
        <div className="menu-wrapper" onMouseLeave={onSelect}>
            <div className="menu-border">&nbsp;</div>
            <Menu theme="dark" style={{ width: "100%" }}>
                {
                    allNoti ?
                        allNoti.map((noti, key) => {
                            return (
                                <Menu.Item key={key}>
                                    <Link to={getNotiLink(noti.type)}><span>{noti.content}</span></Link>
                                </Menu.Item>
                            );
                        })
                        : null
                }
            </Menu>
        </div>
    );

    const onSearch = (text) => {
        console.log("Search: ", text);
        if (text) {
            // setShowSearchExpand(false);
            console.log("Text - Search: ", text);
            setSearchResult(<Redirect to={PATH.SEARCH + text} />);
        }
    }

    const onInputTextSearch = (text) => {
        setTextSearchInput(text);
    }

    const searchExpand = showSearchExpand ? (
        <div id="expanded_search">
            <div className="search-box">
                <Input
                    placeholder="Nhập tên phụ tùng"
                    onPressEnter={(e) => onSearch(e.target.value)}
                    className="search-input"
                    onChange={(e) => onInputTextSearch(e.target.value)}
                />
                {searchResult}
                <Icon type="search" className="search-icon" onClick={() => onSearch(textSearchInput)} />
            </div>
        </div>
    ) : null;


    return (
        <div className="profile-box">
            {
                user != null ?
                    <Dropdown overlay={profileMenu} className="profile-dropdown">
                        <a className="ant-dropdown-link" href="#" style={{ whiteSpace: "nowrap", display: "flex" }}>
                            <div className="profile-dropdown-list">
                                <div className="profile-info">
                                    <span className="profile-name">{user.name}</span>
                                    <span className="profile-coin"
                                        style={{ color: `${COLOR.BASE}` }}>{convertPrice(user.money ? user.money : 0)}</span>
                                </div>
                                <Avatar shape="circle" icon="user" src={user.avatar} />
                                <Icon type="setting" className="setting" style={{ fontSize: "1.6em" }} />
                            </div>
                        </a>
                    </Dropdown>
                    : <Spin size="small" />
            }
            <div className="badge-wrapper">

                <Dropdown overlay={notificationList} overlayClassName="sub_header_menu_box">
                    <Badge count={totalUnreadNoti} dot={false}>
                        <Icon type="bell" theme="filled" />
                    </Badge>
                </Dropdown>
                <Badge count={ctxCart ? ctxCart.length : 0} dot={false}>
                    <Link to={PATH.CART}>
                        <Icon type="shopping-cart" style={{ fontSize: "1.68em" }} />
                    </Link>
                </Badge>
            </div>
            {/* <Popover placement="bottomLeft" title={null} content={searchExpand} trigger="click">
                <Icon type="search" className="search-icon" style={{ color: "white", fontSize: "1.5em", marginLeft: "15px" }} />
            </Popover> */}
            <div className="mobile-header-extra">
                <Link to={PATH.HOME}>
                    <Icon type="home" theme="filled" style={{ height: "100%", color: "white", paddingLeft: "14px", fontSize: "1.5em", paddingRight: "14px" }} />
                </Link>
                {/* <Dropdown overlay={searchExpand} overlayClassName="sub_header_menu_box">
                    <Icon type="search" className="mobile-search-icon" style={{ color: "white", fontSize: "1.5em", paddingLeft: "10px" }} />
                </Dropdown> */}
                <Icon type="search" onClick={() => { setShowSearchExpand(!showSearchExpand) }} className="mobile-search-icon" style={{ color: "white", fontSize: "1.5em", paddingLeft: "10px" }} />
                {searchExpand}
            </div>
        </div>
    );
}
