import React, { Fragment, useContext, useState } from 'react';
import { Col, Icon, Row, Spin, Tabs, Collapse, Button } from 'antd';
import { Link } from "react-router-dom";
import * as PATH from "../../common/constant/path";
import NumberBox from "../components/NumberBox";
import { getDate, getTime, getDateTime } from "../../common/helpers/Date";
import { UserLevel } from "../auth/UserLevel";
import { convertPrice } from "../../common/helpers/Price";
import { CartContext, UserContext } from '../../App';
import Price from '../components/Price';
import * as COLOR from '../../common/constant/color';
import { db } from '../../common/firebase/Firestore';
import * as Converter from '../../common/helpers/Converter';
import { isMobile } from 'react-device-detect';
import { canPay, canRequestShip } from '../../hooks/PartHook';
import 'antd/dist/antd.css';
import { payForReadyItem, requestShip } from '../../hooks/ShoppingCart';

const { TabPane } = Tabs;
const { Panel } = Collapse;

export default function OrderHistory(props) {
    const user = useContext(UserContext);
    const ctxCart = useContext(CartContext);
    const [newShipRequests, setNewShipRequests] = useState([]);
    // console.log("History Items: ", user);

    const onPay = (item, orderItem) => {
        // db.
    }

    const onRequestShip = (orderItem) => {
        requestShip(user, orderItem);
        setNewShipRequests([...newShipRequests, orderItem.key]);
    }

    const canShip = (orderItem) => {
        return canRequestShip(orderItem) && !newShipRequests.includes(orderItem.key);
    }

    const firstFieldByDevice = (itemCode, itemName) => {
        var component = itemCode;
        if (isMobile) {

        }
        return component;
    }

    const orderDetailComponent = (orderItem) => {
        return (
            <Panel header={orderHeaderPanel(orderItem)} key={orderItem.key} style={{ border: "none", color: "white", marginBottom: "15px", overflow: "hidden", fontSize: "2.2vmin", backgroundColor: "#262626E6", borderRadius: "6px", color: "white", fontWeight: "500" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", flex: 1, color: "white" }}>
                    <Row style={{ fontSize: "11px", color: "lightgray", borderBottom: "1px solid gray", marginBottom: "10px" }}>
                        <Col md={3} xs={11}>{isMobile ? "Code & name" : "Code"}</Col>
                        <Col md={11} xs={0}>Tên</Col>
                        <Col md={4} xs={5} className="text-right">Giá (VNĐ)</Col>
                        <Col md={3} xs={3} className="text-right" style={{paddingRight:'10px'}}>{isMobile ? "SL" : "Số lượng"}</Col>
                        <Col md={3} xs={5} className="text-right">Tình trạng</Col>
                    </Row>
                    {
                        (orderItem && orderItem.items && Array.isArray(orderItem.items) && orderItem.items.length > 0)
                            ?
                            orderItem.items.map((item) => {
                                return (
                                    <Row key={item.code} style={{ fontSize: "11px", marginBottom: "6px", backgroundColor: "#303030", paddingTop: "4px", paddingBottom: "3px" }}>
                                        <Col md={3} xs={11} style={{ color: "gray" }}>{firstFieldByDevice(item.code, item.des_vn)}</Col>
                                        <Col md={11} xs={0} style={{ fontSize: "1.2em", fontWeight: "bold" }}>{item.des_en} <span style={{ fontWeight: "normal", fontSize: "1em" }}>({item.des_vn})</span></Col>
                                        <Col md={4} xs={5} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold" }} className="text-right"><Price price={item.price_brand ? item.price_brand : item.price_retail} /></Col>
                                        <Col md={3} xs={3} style={{ textAlign: "right", fontSize: "13px", fontWeight: "bold", paddingRight: "10px" }} className="text-right">{item.count}</Col>
                                        <Col md={3} xs={5} className="text-right" style={{ color: `${Converter.getOrderStatusColor(item.status)}` }}>{Converter.getOrderStatus(item.status)}</Col>
                                    </Row>
                                )
                            })
                            : <Spin style={{ margin: "5px" }} size="small" />
                    }

                    <div style={{ fontSize: "13px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                        <div style={{ flex: "0 1 auto", marginRight: "15px" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ color: "lightgray", fontStyle: "italic", fontWeight: "400" }}>Đã thanh toán: </span>
                                <span style={{ color: "lightgray", fontStyle: "italic", fontWeight: "400" }}>Cần thanh toán: </span>
                            </div>
                        </div>
                        <div style={{ flex: "1 1 100px" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <Price price={orderItem.deposite} />
                                <Price price={orderItem.totalPrice - orderItem.deposite} color="orange" />
                            </div>
                        </div>
                        <div className="right-button-wrap" style={{ flex: "1 1 100px" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                {
                                    (orderItem.totalPrice - orderItem.deposite <= 0) ?
                                        <span style={{ color: `${COLOR.BASE}`, width: "max-content", lineHeight: "45px" }}>Đã thanh toán thành công</span>
                                        : <Button
                                            disabled={!canPay(orderItem)}
                                            onClick={() => payForReadyItem(user, orderItem)}
                                            style={{ backgroundColor: `${canPay(orderItem) ? COLOR.BASE : "gray"}`, marginTop: "8px", color: "white", border: "0", fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px" }}
                                        >
                                            THANH TOÁN
                                            </Button>
                                }
                                {
                                    <Button
                                        disabled={!canShip(orderItem)}
                                        onClick={() => onRequestShip(orderItem)}
                                        style={{ backgroundColor: `${canShip(orderItem) ? COLOR.BASE : "gray"}`, marginLeft: "10px", marginTop: "8px", color: "white", border: "0", fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px" }}
                                    >
                                        GIAO HÀNG
                                     </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }

    const orderHeaderPanel = (orderItem) => {
        return (
            <div style={{ color: "white", marginRight: "15px" }}>
                <Row>
                    <Col md={4} xs={10} style={{ fontSize: "14px", color: "gray" }} >
                        <div style={{ height: "45px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            {orderItem.key.substr(0, 10)}
                        </div>
                    </Col>
                    <Col md={3} xs={9}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ flex: 4, fontSize: "11px", color: "gray" }}>{getTime(orderItem.created.seconds)}</span>
                            <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{getDate(orderItem.created.seconds)}</span>
                        </div>
                    </Col>
                    <Col md={4} xs={0}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ flex: 4 }}><Icon type="inbox" /></div>
                            <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px", textAlign: "center" }}>{orderItem.items.length}</span>
                        </div>
                    </Col>
                    <Col md={5} xs={0}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: "13px" }}>
                            <div style={{ flex: 4 }}><Icon type="car" theme="filled" /></div>
                            {
                                orderItem.delivery_date ?
                                    <span style={{ flex: 5, fontSize: "13px" }}>{getDate(orderItem.delivery_date.seconds)}</span>
                                    : <span style={{ flex: 5, color: "orange", fontSize: "12px", fontStyle: "italic" }}>Đang xử lý</span>
                            }
                        </div>
                    </Col>
                    <Col md={3} xs={5}>
                        <div style={{textAlign:'right', height:'45px', display: "flex", flexDirection: "column", justifyContent: "center", fontSize: "12px",color: `${Converter.getOrderStatusColor(orderItem.status)}` }}>
                            {Converter.getOrderStatus(orderItem.status)}
                        </div>
                    </Col>
                    <Col md={5} xs={0} className="text-right">
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <div style={{ flex: 4 }}><Icon type="credit-card" theme="filled" /></div>
                            <span style={{ flex: 5, color: "orange", fontWeight: "bold", fontSize: "15px" }}><Price price={orderItem.totalPrice} color="orange" /></span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <Fragment>
            <Row>
                <Col md={15} xs={24} key="order-history-box">
                    <div className="cart-wrapper" key="cart-wrapper">
                        <div className="cart-inner" key="cart-inner">
                            <Tabs defaultActiveKey="1" animated={false} className="cart-history" key="0">
                                <TabPane tab="Lịch sử đặt hàng" key="1">
                                    <div className="cart-detail" key="CartHistoryDetail">
                                        <div className="cart-table" key="CartTableHistory">
                                            <Row className="cart-header" key="cart-header">
                                                <Col md={4} xs={9}>Mã đơn hàng</Col>
                                                <Col md={3} xs={7} >Ngày đặt</Col>
                                                <Col md={4} xs={0} style={{ textAlign: "center" }}>Số lượng</Col>
                                                <Col md={5} xs={0} >Ngày về dự kiến</Col>
                                                <Col md={3} xs={8} style={{ textAlign: "right", paddingRight:isMobile?'20px':0 }}>Tình trạng</Col>
                                                <Col md={5} xs={0} className="text-right" style={{textAlign:'right'}}>Tổng tiền</Col>
                                            </Row>
                                            <Row style={{ height: "1px", backgroundColor: "gray", marginBottom: "20px" }} key="cart-content"></Row>

                                            {
                                                user.orderHistory && user.orderHistory.length ?
                                                    [user.orderHistory.map((orderItem, key) => {
                                                        return (
                                                            <Collapse key={key} expandIcon={() => <div />} bordered={false} style={{ border: "none", backgroundColor: "transparent", minWidth: "250px", flex: 1 }}>
                                                                {orderDetailComponent(orderItem)}
                                                            </Collapse>
                                                        )
                                                    })]
                                                    : ((user) ? <div style={{ color: "orange" }}>Bạn chưa đặt hàng lần nào. Hãy đặt hàng ngay để tân trang cho tình yêu của mình đi nào!</div> : <Spin size="large" />)
                                            }
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="Lịch sử kho" key="2">
                                    <div className="cart-detail">
                                        <div className="cart-table" style={{ color: "white" }}>
                                            Tính năng này sẽ sớm được cập nhật!
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </Col>
                <Col md={2} xs={0} key="order-history-space">&nbsp;</Col>
                <Col md={7} xs={0} className="user-level" key="order-history-user-level">
                    <UserLevel />
                </Col>
            </Row>
        </Fragment >
    )
}