import React, { useContext, useState, Fragment } from 'react';
import { Col, Row, Form, Input, Icon, Button, Select } from 'antd';
import { UserContext } from '../../App';
import md5 from 'md5';
import { fb, db } from '../../common/firebase/Firestore';
import * as firebase from "firebase/app";
import * as COLLECTIONS from '../../common/firebase/collections';
import * as CITY from '../../common/constant/city';
import * as COLOR from '../../common/constant/color';

const { Option } = Select;

function SettingForm(props) {
    const user = useContext(UserContext);
    var [disable, setDisable] = useState(false);
    var [notice, setNotice] = useState("");
    var [noticeColor, setNoticeColor] = useState("red");
    var [confirmDirty, setConfirmDirty] = useState(false);

    const doUpdateUserInfo = (uid, newInfo) => {
        let userData = {};
        if (newInfo.name) userData.name = newInfo.name;
        if (newInfo.phone) userData.phone = newInfo.phone;
        if (newInfo.email) userData.email = newInfo.email;
        if (newInfo.address) userData.address = newInfo.address;
        if (newInfo.city) userData.city = newInfo.city;
        if (newInfo.shop_address) userData.shop_address = newInfo.shop_address;
        if (newInfo.shop_name) userData.shop_name = newInfo.shop_name;

        if (userData) {
            db.collection(COLLECTIONS.USER).doc(uid).update({ ...userData })
                .then(() => {
                    setNoticeColor("green");
                    setNotice("Cập nhật thông tin thành công!");
                    setDisable(false);
                }).catch(function (error) {
                    console.log("Error: ", error);
                    setNoticeColor("orange");
                    setNotice("Cập nhật thông tin người dùng thất bại!");
                    setDisable(false);
                });;
        }
    }

    const updateInfo = (e) => {
        setDisable(true);
        setNoticeColor("red");
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (user) {
                    if (user.providerData[0].providerId === "password" && (values.new_password && values.password)) {
                        if (values.password) {
                            var newPassword = md5(values.new_password);
                            var credential = firebase.auth.EmailAuthProvider.credential(
                                user.email,
                                values.password
                            );
                            console.log('credential: ', credential);
                            // Prompt the user to re-provide their sign-in credentials

                            user.reauthenticateWithCredential(credential).then(function () {
                                // User re-authenticated.
                                user.updatePassword(newPassword).then(function () {
                                    setNoticeColor("green");
                                    setNotice("Cập nhật mật khẩu thành công!");
                                    console.log('Update pass success: ', newPassword);
                                    doUpdateUserInfo(user.uid, values);
                                }).catch(function (error) {
                                    console.log('Cannot update pass: ', error);
                                    setDisable(false);
                                    setNotice("Không thể cập nhật mật khẩu do có lỗi xảy ra!");
                                    // An error happened.
                                });
                            }).catch(function (error) {
                                // An error happened.
                                console.log('Cannot re-authen: ', error);
                                setNotice("Mật khẩu không chính xác!");
                                setDisable(false);
                            });
                        } else {
                            // user didn't input old password
                            setNotice("Hãy nhập mật khẩu hiện tại!");
                            console.log('Chưa nhập mật khẩu hiện tại');
                            setDisable(false);
                        }
                    } else if (!values.passwor && !values.new_password) {
                        doUpdateUserInfo(user.uid, values);
                    }
                    console.log("Current User: ", user);
                } else {
                    console.log("User signout");
                    setNotice("Không thể lấy thông tin người dùng hiện tại. Hãy thoát ra và đăng nhập lại!");
                    setDisable(false);
                }
            } else {
                console.log('Error: ', err);
                setNotice(err[0].message);
                setDisable(false);
            }
        });
    };

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToOldPassword = (rule, value, callback) => {
        if (disable) setDisable(false);

        const { form } = props;
        if (value && value == form.getFieldValue('password')) {
            callback('Mật khẩu mới trùng với mật khẩu cũ!');
            // } else if(value && confirmDirty ){
            //     form.validateFields(['confirm'], { force: true });
        // } else if (!value && form.getFieldValue('password')) {
        //     callback('Hãy nhập mật khẩu mới!');
        } else {
            callback();
        }
    };

    const compareToNewPassword = (rule, value, callback) => {
        if (disable) setDisable(false);

        const { form } = props;
        if (value && value !== form.getFieldValue('new_password')) {
            callback('Nhập lại mật khẩu không giống!');
        } else if (!value && form.getFieldValue('new_password')) {
            callback('Hãy gõ lại mật khẩu mới một lần nữa!');
        } else {
            callback();
        }
    };

    const validateOldPassword = (rule, value, callback) => {
        if (disable) setDisable(false);

        const { form } = props;
        if (!value && form.getFieldValue('new_password'))
            callback('Bạn chưa nhập mật khẩu hiện tại!');
        else
            callback();
    };

    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <div style={{
            color: "white",
            margin: "0 auto",
            maxWidth: "1196px",
            paddingTop: "5vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingLeft: "20px",
            paddingRight: "20px"
        }}>
            <Row style={{ width: "100%" }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }}><h1 style={{ color: "white" }}>Cài đặt thông tin cá nhân</h1></Col>
            </Row>
            <Row style={{ width: "100%" }}>
                <Col xs={{ span: 8, offset: 0 }} sm={{ span: 8, offset: 0 }} style={{ textAlign: "right", fontStyle:"italic" }}>Mã khách hàng: &nbsp; &nbsp;  </Col>
                <Col xs={{ span: 16, offset: 0 }} sm={{ span: 16, offset: 0 }} style={{ fontSize: "1.12em", fontWeight:"bold", color:`${COLOR.BASE}` }}>{user.index}</Col>
            </Row>

            <hr />
            <Form {...formItemLayout} onSubmit={updateInfo} className="setting-form" style={{ width: "100%" }}>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Tên đầy đủ: ">
                    {getFieldDecorator('name', {
                        rules: [{ message: 'Hãy nhập tên đầy đủ của bạn!' }],
                        initialValue: `${user.name}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Hãy nhập tên của bạn!"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>

                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Số điện thoại: ">
                    {getFieldDecorator('phone', {
                        rules: [{ message: 'Hãy nhập đúng số điện thoại của bạn!' }],
                        initialValue: `${(user.phone) ? user.phone : ""}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Số điện thoại liên hệ!"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="E-mail: ">
                    {getFieldDecorator('email', {
                        rules: [{ message: 'Hãy nhập email bạn hay dùng nhất!' }],
                        initialValue: `${(user.email) ? user.email : ""}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Địa chỉ email của bạn!"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Tỉnh/Thành phố: ">
                    {getFieldDecorator('city', {
                        rules: [{ message: 'Hãy Chọn nơi bạn đang sinh sống!' }],
                        initialValue: `${(user.city) ? user.city : ""}`
                    })(
                        <Select placeholder="Nơi bạn đang sinh sống" style={{ maxWidth: "300px" }}>
                            {
                                CITY.ALL_CITY.map((city) => {
                                    return (<Option value={city}>{city}</Option>);
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Địa chỉ: ">
                    {getFieldDecorator('address', {
                        rules: [{ message: 'Hãy nhập địa chỉ bạn muốn nhận hàng!' }],
                        initialValue: `${user.address}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Bạn muốn được giao hàng ở đâu?"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Tên cửa hàng: ">
                    {getFieldDecorator('shop_name', {
                        rules: [{ message: 'Hãy nhập tên cửa hàng của bạn!' }],
                        initialValue: `${user.shop_name}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Bạn đang làm chủ 1 cửa hàng nào?"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Địa chỉ cửa hàng: ">
                    {getFieldDecorator('shop_address', {
                        rules: [{ message: 'Hãy nhập địa chỉ cửa hàng của bạn!' }],
                        initialValue: `${user.shop_address}`
                    })(
                        <Input
                            style={{ maxWidth: "300px" }}
                            placeholder="Cửa hàng của bạn ở đâu?"
                            onChange={()=>{setDisable(false)}}
                        // disabled={disable} 
                        />,
                    )}
                </Form.Item>
                {
                    (user && user.providerData && Array.isArray(user.providerData) && user.providerData[0].providerId === "password") ? <Fragment>
                        <Form.Item style={{ marginBottom: "10px", color: "white" }} label="Mật khẩu: ">
                            {getFieldDecorator('password', {
                                rules: [
                                    { required: false, message: 'Please input your Password!' },
                                    { validator: validateOldPassword }
                                ],
                                initialValue:""
                            })(
                                <Input.Password
                                    style={{ maxWidth: "200px" }}
                                    placeholder="Old Password"
                                    defaultValue=""
                                    value=""
                                    onChange={()=>{setDisable(false)}}
                                // disabled={disable} 
                                />,
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "10px", color: "white" }} hasFeedback label="Mật khẩu mới: ">
                            {getFieldDecorator('new_password', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                    {
                                        validator: compareToOldPassword,
                                    },
                                ],
                            })(<Input.Password
                                style={{ maxWidth: "200px" }}
                                placeholder="New Password"
                                onChange={()=>{setDisable(false)}}
                            // disabled={disable}
                            />)}
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "10px", color: "white" }} hasFeedback label="Nhập lại mật khẩu:">
                            {getFieldDecorator('confirm', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please confirm your password!',
                                    },
                                    {
                                        validator: compareToNewPassword,
                                    },
                                ],
                            })(<Input.Password
                                style={{ maxWidth: "200px" }}
                                onBlur={handleConfirmBlur}
                                placeholder="Re-type new password"
                                onChange={()=>{setDisable(false)}}
                            // disabled={disable}
                            />)}
                        </Form.Item>
                    </Fragment>
                        : null
                }

                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 8 }} >
                        <span style={{ color: noticeColor }}>{notice}</span>
                    </Col>
                </Row>
                <Form.Item  {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" style={{ width: "100%", height: "30px", maxWidth: "150px" }} disabled={disable}>
                        Xác nhận
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

const Setting = Form.create({ name: 'setting' })(SettingForm);
export default Setting;