import moment from 'moment';

export const getDateTime = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY H:mm:ss');
}

export const getDate = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY');
}

export const getTime = (timestamp) => {
    return moment.unix(timestamp).format('H:mm:ss');
}
export const sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }