import React, { useState, useContext, Fragment } from 'react';
import { Row, Col, Icon, notification, Button, Collapse } from 'antd';
import ProductStyle from './product.module.css';
import NumberBox from '../components/NumberBox';
import * as COLOR from '../../common/constant/color';
import { getNewCode, getOldCode } from '../../common/helpers/Converter';
import { db, fb } from '../../common/firebase/Firestore';
import { addToCart, getRemainCartSlot } from '../../hooks/ShoppingCart';
import * as COLLECTIONS from '../../common/firebase/collections';
import * as PATH from '../../common/constant/path';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import SearchPartGroupResult from './SearchPartGroupResult';
import './search.scss';
import {
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";
import { Modal } from 'antd';
import { isMobile } from 'react-device-detect';

const { Panel } = Collapse;

export default function PartSearchResultItem(props) {
    const user = useContext(UserContext);

    const part = props.part;

    const [oldSelectedNumber, setOldSelectedNumber] = useState(0);
    const [total, setTotal] = useState(part.repeat);
    // const [shoppingCartItem, setShoppingCartItem] = useState(null);
    const [redirectComponent, setRedirectComponent] = useState(null);
    const [modalOver, setModalOver] = useState(false);

    const onChangeNumber = (newNumber) => {
        setTotal(newNumber);
    }

    const openNotification = (part, count) => {
        if (part && count)
            notification.open({
                message: 'Thêm vào giỏ hàng thành công',
                description:
                    'Bạn đã thêm ' + count + ' sản phẩm ' + part.des_en + " (" + part.des_vn + ")" + " [" + part.code + "] vào giỏ hàng. Click vào đây để xem giỏ hàng của bạn!",
                onClick: () => {
                    gotoShoppingCart();
                },
            });
        console.log("2. DONE SHOW NOTIFICATION");
        // setShoppingCartItem(null);
    };

    const addToShoppingCart = (part, count) => {
        console.log("1. Add to shop cart: partId " + part.code + " - count: " + count, user);
        if (getRemainCartSlot(user) <= 0) {
            setModalOver(true);
        } else if (oldSelectedNumber != count) {
            setOldSelectedNumber(count);
            addToCart(part, count, user, openNotification);
        }
    }

    const gotoShoppingCart = () => {
        console.log('Notification Clicked!');
        setRedirectComponent(<Redirect to={PATH.CART} />);
    }

    const goToProfile = () => {
        setRedirectComponent(<Redirect to={PATH.ORDER} />);
    }

    const searchResultHeader = () => {
        return (
            <Row className={ProductStyle.part_item_box}>
                <Col md={3} xs={4} style={{ height: "100%" }}>
                    <div className="ref-code">
                        {
                            (getOldCode(part) != null) ?
                                <span className="old_code">{getOldCode(part)} &nbsp;</span>
                                : null
                        }
                        <span className={part.out_date ? "new_code" : "code"}>{getNewCode(part)}</span>
                    </div>
                </Col>
                <Col md={11} xs={11} className={ProductStyle.name_box}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "left", lineHeight: "18px", overflow: "auto", textOverflow: "clip" }}>
                        <span className="title">{part.des_en}</span>
                        <span className="sub-title">{part.des_vn}</span>
                    </div>
                </Col>
                {/* <Col md={4} xs={0}>
                    <div className="bikes-info">
                        <span style={{ color: "gray", fontSize: "11px", overflow: "hidden", display: "-webkit-box", lineHeight: "normal", maxBlockSize: "-webkit-fill-available" }}>
                            {part.bikes ? part.bikes.join(", ") : null}
                        </span>
                    </div>
                </Col> */}
                <Col md={2} xs={2} style={{ textAlign: "center", fontSize: "18px", paddingTop: "5px" }}>
                    {/* <Link to={PATH.BRAND + part.brand + "/" + part.bike + "/" + part.bikeClass + "/" + part.group_id}> */}
                    <Icon type="environment" theme="filled" color="orange" />
                    {/* </Link> */}
                </Col>
                <Col md={3} xs={3} style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <span className="inventory" style={{ color: `${COLOR.BASE}`, width: "100%" }}>{(parseInt(part.inventory) > 0) ? (parseInt(part.inventory)) : ""}</span>
                </Col>
                <Col md={3} xs={0}>
                    <NumberBox onChange={onChangeNumber} default={parseInt(part.repeat) ? parseInt(part.repeat) : 0} />
                </Col>
                <Col md={2} xs={4} className={ProductStyle.part_item_col}
                    style={{ paddingLeft: "3px", paddingRight: "0px" }}>
                    <div className={(oldSelectedNumber == total) ? ProductStyle.shopping_cart_disable : ProductStyle.shopping_cart}
                        onClick={() => {
                            addToShoppingCart(part, total)
                        }}>
                        <Icon type="shopping-cart" style={{ fontSize: "24px" }} />
                    </div>

                    {redirectComponent}
                </Col>
            </Row>
        );
    }

    const resultItemComponent = () => {
        return (
            <Panel header={searchResultHeader()} key={part.key} style={{ border: "none", color: "white", overflow: "hidden", fontSize: "2.2vmin", borderRadius: "6px", fontWeight: "500" }}>
                <div style={{ display: "flex", flexDirection: "column", color: "white" }}>
                    <SearchPartGroupResult part={part} />
                </div>
            </Panel>
        );
    }

    return (
        <Fragment>

            <Collapse
                expandIcon={() => <div />}
                bordered={false}
                className="site-collapse-custom-collapse"
                style={{ border: "none", backgroundColor: "transparent", minWidth: "250px", flex: 1 }}>
                {resultItemComponent()}
            </Collapse>


            <Modal
                title="CÓ LỖI XẢY RA"
                centered
                visible={modalOver}
                onCancel={() => setModalOver(false)}
                style={{ maxWidth: "400px", border: "none" }}
                bodyStyle={{ background: "black", paddingRight: "50px", paddingLeft: "50px", paddingTop: 0, color: "white", textAlign: "center" }}
                closeIcon={null}
                footer={
                    <div style={{ backgroundColor: "black", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: 0, paddingRight: "40px", paddingLeft: "40px", paddingBottom: "30px" }}>
                        <Button style={{ color: "darkgray", backgroundColor: "transparent", flex: 1 }} onClick={() => setModalOver(false)}>BỎ QUA</Button>
                        <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", flex: 1, border: "none" }} onClick={() => goToProfile()}>NÂNG CẤP</Button>
                    </div>
                }
                maskStyle={{ backgroundColor: "rgba(50,50,50,0.8)" }}
            >
                <div style={{ height: "1px", background: "gray", marginBottom: "30px" }} />
                <span>Bạn đã hết lượt thêm sản phẩm vào giỏ hàng. </span>
                <span>Hãy liên hệ Admin để nâng cấp lên tài khoản. Tài khoản cấp độ cao hơn sẽ cho phép thêm nhiều sản phẩm vào giỏ hàng hơn </span>
            </Modal>
        </Fragment>
    );
}