import React, { useContext, useEffect, useState, Fragment } from 'react';
import PartName from '../products/PartName';
import PageIntro from "../components/partials/page-intro";
import Price from '../components/Price';
import { Link } from 'react-router-dom';
import * as PATH from '../../common/constant/path';
import * as COLOR from '../../common/constant/color';
import { Col, Icon, Row, Spin, Button, Popconfirm, message, Modal } from 'antd';
import '../products/cart.scss';
import FeatureParts from "../components/feature-part/FeatureParts";
import { CartContext, UserContext } from "../../App";
import * as COLLECTIONS from '../../common/firebase/collections';
import { db, fb } from '../../common/firebase/Firestore';
import { PROFILE_STAGE } from '../auth/Profile';
import * as firebase from "firebase/app";
import * as NAME from '../../common/constant/name';
import { getDate, getTime } from "../../common/helpers/Date";
import { isMobile } from 'react-device-detect';

import {
    BrowserRouter as Router,
    Redirect
} from "react-router-dom";

export default function MoneyHistory(props) {
    const user = useContext(UserContext);
    const [redirectComponent, setRedirectComponent] = useState(null);

    const goToRechargeScreen = () => {
        setRedirectComponent(<Redirect to={PATH.RECHARGE} />);
    }

    const subTitle = (exchangeItem) => {
        if (exchangeItem.exchange_type === NAME.EXCHANGE_TYPE.RECHARGE)
            return ((isMobile ? "Nạp tiền - " : "") + "Mã GD: " + exchangeItem.recharge_id);
        else return ("Mã đơn: " + exchangeItem.order_id);
    }

    const firstColumnContent = (exchangeItem) => {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ flex: 4, fontSize: "11px", color: "gray" }}>{getTime(exchangeItem.created.seconds)}</span>
                <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{getDate(exchangeItem.created.seconds)}</span>
                {
                    isMobile ? (
                        <Fragment>
                            <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{exchangeItem.content}</span>
                            <span style={{ flex: 4, fontSize: "11px", color: "gray" }}>{subTitle(exchangeItem)}</span>
                        </Fragment>
                    ) : null
                }
            </div>
        );
    }

    const exchangeItemComponent = (exchangeItem, key) => {
        return (
            <Row className="cart-row" key={key}>
                <Col md={3} xs={16}>
                    {firstColumnContent(exchangeItem)}
                </Col>
                <Col md={5} xs={0} >
                    {
                        !isMobile ?
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{exchangeItem.exchange_type}</span>
                                <span style={{ flex: 4, fontSize: "11px", color: "gray" }}>{subTitle(exchangeItem)} </span>
                            </div>
                            : null
                    }
                </Col>
                <Col md={8} xs={0}>
                    {
                        !isMobile ?
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <span style={{ flex: 5, fontWeight: "bold", fontSize: "13px" }}>{exchangeItem.content}</span>
                            </div>
                            : null
                    }
                </Col>
                <Col md={4} xs={8}>
                    {isMobile ? <span style={{ textAlign: "right", fontSize: "0.8em", color: "lightgray" }}>GTGD (VND)</span> : null}
                    <div className="product-total">
                        {(exchangeItem.exchange_type !== NAME.EXCHANGE_TYPE.RECHARGE) ? "- " : "+"}
                        <Price
                            price={exchangeItem.amount}
                            color={(exchangeItem.exchange_type === NAME.EXCHANGE_TYPE.RECHARGE) ? `${COLOR.BASE}` : "orange"} />
                    </div>
                    {
                        isMobile ?
                            <Fragment>
                                <span style={{ textAlign: "right", fontSize: "0.8em", color: "lightgray", marginTop: "8px" }}>Số dư (VND)</span>
                                <div className="product-total">
                                    <Price price={exchangeItem.after} />
                                </div>
                            </Fragment>
                            : null
                    }
                </Col>
                <Col md={4} xs={0}>
                    {
                        !isMobile ?
                            <div className="product-total">
                                <Price price={exchangeItem.after} />
                            </div>
                            : null
                    }
                </Col>
            </Row>
        )
    }

    return (
        <div className="cart">
            {/* <div className="container"> */}
            <Row>
                <Col>
                    <div className="cart-top">
                        Lịch sử tài khoản
                        </div>
                    <div className="cart-wrapper">
                        <div className="cart-inner">
                            <div className="cart-detail">
                                <div className="cart-table">
                                    {
                                        isMobile ? null
                                            : <Row className="cart-header">
                                                <Col span={3}>Thời gian</Col>
                                                <Col span={5}>
                                                    <div>Loại GD</div>
                                                </Col>
                                                <Col span={8}>Nội dung</Col>
                                                <Col span={4} className="text-right" style={{paddingRight:'24px'}}>Giá trị giao dịch (VNĐ)</Col>
                                                <Col span={4} className="text-right" style={{paddingRight:'24px'}}>Số dư (VNĐ)</Col>
                                            </Row>
                                    }
                                    {
                                        (user && user.money_history && Array.isArray(user.money_history) && user.money_history.length > 0) ?
                                            user.money_history.map((exchange, key) => {
                                                return (
                                                    exchangeItemComponent(exchange, key)
                                                );
                                            })
                                            : (user ? <div style={{ color: "orange", marginTop: "20px" }}>Bạn chưa có giao dịch nào!</div> : <Spin style={{ margin: "50px" }} size="large" />)
                                    }
                                </div>
                            </div>
                            <div className="cart-actions">
                                <Button style={{ backgroundColor: `${COLOR.BASE}`, color: "white", border: "0", fontWeight: "bold", paddingRight: "20px", paddingLeft: "20px" }} onClick={goToRechargeScreen}>Nạp tiền</Button>
                            </div>
                            <br />
                        </div>
                    </div>
                </Col>
            </Row>
            {/* </div> */}

            {redirectComponent}
        </div>
    )
}