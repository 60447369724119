import React from 'react';
import * as COLOR from '../../common/constant/color';
import bg_baner from '../../resources/bg_banner_gara.png';

import {Button, Icon} from 'antd';

const FIND_STATE = {
    BIKE: "Loại xe",
    YEAR: "Năm sản xuất",
    GROUP: "Nhóm phụ tùng",
    PART: "Phụ tùng"
}

const activeTab = {
    backgroundColor: `${COLOR.BASE}`,
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "12px",
    width: "auto"
}
const deactiveTab = {
    backgroundColor: "#4A4A4A",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "12px",
    width: "auto"
}

export default function ProductBanner(props) {
    const state = props.state ? props.state : FIND_STATE.BIKE;
    const title = props.title;
    const changeStage = props.onChangeStage;
    const bike = props.bike;
    const year = props.year;
    const partGroup = props.partGroup;
    const onBack = props.onBack;
    // console.log("PartGroup: ", partGroup);

    return (
        <div style={{ background: `url(${bg_baner})`, backgroundColor: "#191919B3", backgroundBlendMode: "multiply", backgroundSize: "cover", backgroundPosition: "center", height: "230px", width: "100%" }}>
            <div style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0 auto", maxWidth: "1196px", height: "100%", display: "flex", justifyContent: "end", flexDirection: "column-reverse", alignItems: "left" }}>
                <div style={{ display: "flex", height: "18px", marginTop: "8px" }}>
                    <div style={(state === FIND_STATE.BIKE) ? activeTab : deactiveTab} onClick={(state === FIND_STATE.YEAR || state === FIND_STATE.GROUP || state === FIND_STATE.PART) ? (changeStage ? (() => { changeStage(FIND_STATE.BIKE) }) : (() => { })) : (() => { })}>
                        <span style={(state !== FIND_STATE.BIKE) ? ({ cursor: "pointer" }) : {}}>{(state !== FIND_STATE.BIKE) ? (bike ? bike.name : FIND_STATE.BIKE) : FIND_STATE.BIKE}</span>
                    </div>
                    <div style={(state === FIND_STATE.YEAR) ? activeTab : deactiveTab} onClick={(state === FIND_STATE.GROUP || state === FIND_STATE.PART) ? (changeStage ? (() => { changeStage(FIND_STATE.YEAR) }) : (() => { })) : (() => { })}>
                        <span style={(state === FIND_STATE.GROUP || state === FIND_STATE.PART) ? { cursor: "pointer" } : {}}>
                            {(state === FIND_STATE.GROUP || state === FIND_STATE.PART) ? year : FIND_STATE.YEAR}
                        </span>
                    </div>
                    <div style={(state === FIND_STATE.GROUP || state === FIND_STATE.PART) ? activeTab : deactiveTab}>
                        <span style={(state === FIND_STATE.PART) ? { cursor: "pointer" } : {}}>
                            {(state === FIND_STATE.PART) ? (partGroup ? partGroup.name : FIND_STATE.GROUP) : FIND_STATE.GROUP}
                        </span>
                    </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    {
                        (state == FIND_STATE.PART) ?
                            <div onClick={onBack} style={{ cursor: "pointer", color: `${COLOR.BASE}` }}>
                                <Button style={{border: "soldid", borderColor:"rgb(36, 187, 0)", background: "rgb(44,44,44)", fontSize:"16px", color: "lightgray"}}>
                                <Icon type="left" />
                                     Back
                                </Button>
                            </div>
                            : <div></div>
                    }
                    <span style={{ fontSize: "6vmin", fontWeight: "bold" }}>{title}</span>
                </div>
            </div>
        </div>
    );
}

export { FIND_STATE, ProductBanner }