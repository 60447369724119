
export const HOME = "/";
export const LOGIN = "/login";
export const BRAND = "/brand/";
export const BIKE = "/bike/";
export const CLASS = "/class/";
export const YEAR = "/class/";
export const PART_GROUP = "/group";
export const PART = "/part";
export const SEARCH = "/search/";
export const GUIDE_LINE = "/help/";

export const PROFILE = "/profile/";
export const SETTING = "/setting";

export const PRODUCT = "/product/";


export const ORDER = "/profile/history";
export const ACCOUNT = "/profile/account";
export const RECHARGE = "/profile/recharge";
export const CART = "/cart/";